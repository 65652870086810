export const en = {
    brakes: {
        description: `
            AVANTECH brake pads are manufactured at modern manufacturing plants in South Korea, which are OEM suppliers to the conveyors of large car manufacturers, including HYUNDAI, KIA MOTORS, RENAULT SAMSUNG, FUSO.
            <br><br>
            AVANTECH brake discs are manufactured at its partner's factories in Taiwan. Long-term cooperation with OEM-manufacturers confirms the level of quality of the products of this plant and entitles our partner plant to supply original spare parts to the world's leading automakers (Toyota, Honda, Nissan).
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em',
            //         'z-index': '1',
            //         'position': 'relative'
            //     },
            //     pseudo: {
            //         type: 'lines-through',
            //         index: 0,
            //         rotate: 3.65,
            //         top: 30,
            //         right: -95,
            //         scaleX: .8,
            //         scaleY: .8 
            //     },
            //     videos: [
            //         {
            //             link: 'ogNBjgUcqO0',
            //             title: 'причины скрипа <br>тормозных колодок',
            //             description: 'Почему скрипят тормозные колодки? Рассказываем самые частые, но не очевидные причины скрипа.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'padding-bottom': '12em'
                },
                title: '<b>BRAKE PADS</b>',
                image: 'brake-pads.png',
                description: 'AVANTECH offers motorists disc and drum brake pads, which guarantee the safety of the car at any speed and in any weather.'
            },
            {
                type: 'product-type',
                styles: {
                    'margin-bottom': '5em',
                    'padding': '10em 0'
                },
                image: 'brake-shoes.png',
                imageStyles: {
                    'top': '0'
                },
                description: 'Brake pads AVANTECH have high technical characteristics, provide smooth efficient braking. They meet the requirements of ECE-R90 - the European product quality standard, which is issued after a series of tests of brake pads for performance and compliance with the original part.'
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        title: 'Overheat resistance',
                        description: 'The key factor in the professional selection of brake pads is the stability of the friction material in a wide temperature range, resistance to overheating.<br><br>The friction material of AVANTECH brake pads is similar to the original one, which is used in the production of brake pads for new cars.'
                    },
                    {
                        title: ' ',
                        description: 'The heating temperature of the brake pads in contact with the disc in normal mode is +100 - 200°C, while driving on a long descent, the temperature loads increase to +350 - 400°C.'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'BRAKE SHOE FEATURES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 5,
                            right: 56,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Stable braking properties in various traffic modes.',
                                    'Non-aggressive effect on the brake disc - as a result, small and uniform wear of the brake disc.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Mechanical strength of pads according to OEM standards.',
                                    'Design for effective surface cleaning from frictional dust.',
                                    'The absence of asbestos in the composition of the pads.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The main range of disc brake pads is equipped with anti-squeal plates.',
                                    'Temperature range up to 400 °C',
                                    'Friction coefficient 0.35-0.48µ'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Free anti-squeal lubricant for disc brake pads included (country of production - South Korea).',
                                    'Electronic wear sensors for car models using the appropriate system.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 178.2,
                    top: -65,
                    right: -10,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>Brake discs and drums</b>',
                image: 'brake-rotor.png',
                description: 'The braking system is the most important means of ensuring the active safety of the car. Despite the fact that the disc type brake system has become widespread recently, automakers still use drum brakes on the rear axle. In a drum-type system, braking is carried out by pressing the brake pads against a rotating drum. Brake discs and drums AVANTECH have high technical characteristics and meet all the requirements of OEM-manufacturers for the brake system. The hubs of the discs and drums are painted with a special paint that withstands high temperatures and guarantees a high degree of protection against corrosion.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'BRAKE DISC FEATURES:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: -1,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH products are made of a special cast iron alloy with a high carbon content. Such discs are successfully used by athletes in automobile competitions.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The use of STOP and GO disc surface treatment technology reduces the lapping time of discs with pads. STOP and GO increases the coefficient of adhesion and ensures that new brake pads are quickly bedded in after the first braking.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Optimum thermal conductivity provides increased resistance to deformation and prevents the destruction of the disk when it is overheated. Disc design eliminates vibration and reduces braking noise.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The use of READY TO GO technology in the production of AVANTECH brake discs eliminates the need for car owners to remove the protective layer of oil from the surface of the disc before use.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'overflow': 'hidden',
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 30,
                    top: 55,
                    right: -32,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>PERFORATED BRAKE DISCS</b>',
                image: 'brake-rotor-perf.png',
                imageStyles: {
                    'z-index': '3',
                    'right': '15%'
                },
                description: 'Designed for installation on heavy and sports cars, the brake systems of which are under increased stress. Specially designed ventilation holes improve the removal of gases and heat from the contact point of the brake pad and disc, thereby increasing braking efficiency.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: -141.3,
                    top: -280,
                    right: 48,
                    scaleX: 1.2,
                    scaleY: 1.2 
                },
                tables: [
                    {
                        title: 'FEATURES OF PERFORATED BRAKE DISCS:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 90,
                            top: 16,
                            right: 98,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH products are made of a special cast iron alloy with a high carbon content. Such discs are successfully used by athletes in automobile competitions.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'A special processing technology reduces the grinding time of discs with pads.',
                                    'Disc design eliminates vibration and reduces braking noise.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Optimum thermal conductivity provides increased resistance to deformation and prevents the destruction of the disk when it is overheated.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The use of READY TO GO technology in the production of AVANTECH brake discs eliminates the need for car owners to remove the protective layer of oil from the surface of the disc before use.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};