export const en = {
    pumps: {
        description: `
            For the stable operation of an internal combustion engine, a continuous supply of fuel in the right amount and at the right pressure is required. This supply is provided by the fuel system of the engine. The heart of the fuel system is the fuel pump.
            <br>
            Thus, the fuel pump is one of the critical components of the car, ensuring its operation.`,
        videos: [],
        content: [
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'padding': '13em 0'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -110,
                    right: -40,
                    scaleX: 1,
                    scaleY: 1 
                },
                image: 'pumps-fuel.png',
                description: 'The products are compatible with a large number of Korean and Japanese vehicles.<br><br>Avantech fuel pumps are manufactured in the Republic of Korea and meet international requirements as well as OEM specifications.'
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '7em',
                    'min-height': '100px',
                    'padding-bottom': '45px',
                    '--lg-min-height': '250px'
                },
                pseudo: {
                    type: 'lines-pumps',
                    index: 0,
                    rotate: 0,
                    top: -150,
                    right: 23.5,
                    scaleX: 1,
                    scaleY: 1.5 
                },
                box: [
                    {
                        tag: 'b',
                        content: 'Avantech fuel pumps have excellent features:'
                    },
                    {
                        tag: 'li',
                        content: 'High build quality',
                    },
                    {
                        tag: 'li',
                        content: 'Conforming to specification performance',
                    },
                    {
                        tag: 'li',
                        content: 'Long service life',
                    }
                ]
            },
            {
                type: 'table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                columns: [
                    {
                        header: 'Vendor code',
                        values: ['AFP0101', 'AFP1001', 'AFP0104', 'AFP0105', 'AFP0106', 'AFP0107', 'AFP0108', 'AFP0301', 'AFP0701', 'AFP1801']
                    },
                    {
                        header: 'Working<br>pressure, kPa',
                        values: ['310', '350', '120', '300', '310', '310', '300', '350', '100', '400']
                    },
                    {
                        header: 'Maximum pressure, kPa',
                        values: ['600', '600', '250', '600', '600', '600', '600', '900', '270', '800']
                    },
                    {
                        header: 'Productivity, l/h',
                        values: ['90', '80', '110', '120', '90', '80', '100', '90', '55', '140']
                    },
                    {
                        header: 'Operating voltage, V',
                        values: ['12', '12', '12', '12', '12', '12', '12', '12', '12', '12']
                    },
                ]
            },
        ]
    }
};