import { ru } from "./lang/ru_Ru.js";
import { en } from "./lang/en_En.js";
import { kr } from "./lang/kr_Kr.js";
import { messages as fb } from "vue3-usf-feedback-form/dist/locales/index.js";
import { messages as bc } from '@components/bundle/Breadcrumb/locales'
import { messages as cp } from '@components/catalog/locales'

let messages = { ru, en, kr };
let langs = new Array();
const components = [fb, bc, cp];

components.forEach((component) => {
    for (const item in messages) {
        langs.push(item);
        messages[item] = Object.assign(messages[item], component[item] ?? {});
    }
});

export { messages, langs };
