export const en = {
    rubber: {
        description: `The most loaded components of the car are the parts of the chassis, first of all, rubber products (RTI), which take on the largest part of the shock loads from driving on uneven pavement. This category includes silent blocks of levers, bushings of anti-roll bars, shock absorber mounts, as well as other rubber goods that are responsible for the correct operation of components such as the engine and transmission of a car. This includes the supports (pillows) of the engine and transmission of the car.`,
        videos: [],
        content: [
            {
                type: 'description-box',
                split: true,
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '3',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: 100,
                    right: 95,
                    scaleX: 1,
                    scaleY: 1 
                },
                templates: [
                    {
                        title: 'Conform to quality standards',
                        description: 'AVANTECH silent blocks and bushings have proven themselves well when operating in the harsh Russian climate with low temperatures. In Russian laboratories, AVANTECH products have successfully passed all tests for tearing, torsion, displacement, which allows us to guarantee compliance with international quality standards.'
                    },
                    {
                        title: 'Production',
                        description: 'Avantech Parts Group manufactures rubber products under the AVANTECH brand of suspension and steering parts at spare parts manufacturing plants located in Thailand and Malaysia. On the conveyors of these plants, both original and analog parts of world brands are produced.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 84.1,
                    top: -15,
                    right: -180,
                    scaleX: .9,
                    scaleY: .9 
                },
                title: '<b>SILENTBLOCKS</b>',
                image: 'rubber-suspension.png',
                imageStyles: {
                    'right': '5%',
                },
                description: 'Needed to take the shock load on the suspension. The silent block is a rigid swivel joint, in which there is a rubber insert between two metal bushings. It is the state of the silent blocks that determines the wear rate of car tires, the quality of the car\'s braking, as well as the connection with the steering. A faulty silent block leads to accelerated wear of all suspension parts, and hence to expensive car repairs in the future.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'ADVANTAGES OF SILENTBLOCKS:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 90,
                            top: 22,
                            right: 98,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'AVANTECH silent blocks are developed from a high-quality rubber compound with increased wear resistance, which provides long-term excellent control over the car\'s handling in the most extreme driving conditions.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The rubber compound used in AVANTECH silent blocks is guaranteed to retain elasticity, resilience and strength at temperatures up to -40⁰С.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Conform to the original quality standards of car manufacturers.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '11em 0 9em'
                },
                title: '<b>BUSHINGS</b>',
                image: 'rubber-hub.png',
                imageStyles: {
                    'z-index': '4'
                },
                description: 'The anti-roll bar is used to reduce the lateral rolls of the car in corners. His work is based on the redistribution of the load. It is necessary and even indispensable when driving at high speeds. The stabilizer helps the car to go through the turn as quickly as possible without losing speed, preventing it from tipping over.<br><br>The effective operation of the anti-roll bar is ensured by elastic elements, one of which is the anti-roll bar bushings. Thanks to the combined work of the stabilizer parts, we feel comfortable and safe when passing sharp turns.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding': '5em 0 7.5em',
                    'background': '#FFF',
                    'z-index': '3',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'STABILIZER BUSHING FEATURES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 10,
                            right: 83,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH stabilizer bushings are made from a high-quality rubber compound with increased wear resistance.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'They are designed for operation in harsh Russian conditions.',
                                    'Reduce vibration levels.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'They have high strength and resistance to mechanical stress.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Conform to the original quality standards of car manufacturers.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};