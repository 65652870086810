export const ru = {
    wipers: {
        description: `
            Если ухудшился обзор, особенно в дождь, слякоть, или щетки стеклоочистителя стали оставлять разводы, пропускать некоторые области лобового стекла и оставлять полосы воды — значит, пришло время щетки заменить.
            <br><br>
            Щетки стеклоочистителя AVANTECH представлены всеми типами конструкций, рассчитанных на летнюю, зимнюю и всесезонную эксплуатацию. Любой желающий сможет подобрать подходящую модель как на зарубежный, так и отечественный автомобиль.
        `,
        videos: [],
        content: [
            {
                type: 'video',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '12.5em'
                },
                videos: [
                    {
                        link: 'v7sxH7sGxfE',
                        title: 'Виды щеток стеклоочистителя',
                        description: 'Показываем какие бывают дворники, в чем их отличия и особенности каждого вида на примере дворников Avantech.'
                    },
                    {
                        link: 'KS1Ul-C-2t8',
                        title: 'Замена лезвий на щетках стеклоочистителя',
                        description: 'В этом видео мы покажем, как сменить резиновые лезвия на каркасных, бескаркасных и гибридных щетках стеклоочистителя Avantech.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'overflow': 'hidden'
                },
                title: 'ГИБРИДНЫЕ ЩЕТКИ<br><b>HYBRID PLUS</b>',
                image: 'wipers-hybrid-plus.png',
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -90,
                    right: -34,
                    scaleX: 1,
                    scaleY: 1 
                },
                description: 'Данная линейка сочетает два типа щеток стеклоочистителя - для автомобилей с правым и левым расположением руля. Напомним, что у гибридных щеток аэродинамический каркас имеет несимметричную структуру. Соответственно, для автомобилей с разным расположением руля необходимо использовать свою щетку.'
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        image: 'wipers-right-hd.png',
                        title: 'Правое расположение руля',
                        description: 'Щетки AVANTECH HYBRID PLUS для автомобилей с правым расположением руля имеют артикулы типа HR-16, HR-18 и т.д. Количество размеров – 13 (от 300 до 700 мм).'
                    },
                    {
                        image: 'wipers-left-hd.png',
                        title: 'Левое расположение руля',
                        description: 'Щетки AVANTECH HYBRID PLUS для автомобилей с левым расположением руля имеют артикулы типа HL-16, HL-18 и т.д. Количество размеров – 13 (от 300 до 700 мм).'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'ПРЕИМУЩЕСТВА ГИБРИДНЫХ ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Стильный дизайн щетки дополняет технологичный экстерьер современных автомобилей.',
                                    'Конструкция и производство щетки по ОЕМ-стандартам.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Превосходное качество резинового элемента и его прочная фиксация в каркасе щетки.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Использование в основе каркаса материала с 45% содержанием стекловолокна гарантирует длительный срок службы, надежность конструкции, равномерный прижим щетки к стеклу.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Специальный состав резины обеспечивает эффективную работу щетки в широком диапазоне температур.',
                                    'Коннектор разработан для установки на крюки двух типов: 9х3 (стандартный) и 9х4 (широкий).'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '12em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 66.4,
                    top: -120,
                    right: 80,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'универсальные ЩЕТКИ<br><b>AERODYNAMIC</b> и <br><b>AERODYNAMIC HD</b>',
                image: 'wipers-aerodynamic-w-hd.png',
                description: 'Каркасные (универсальные) щетки стеклоочистителя вобрали в себя мировой опыт последних десятилетий производства и инженерных разработок. Сочетание современных материалов и классического устройства гарантируют максимальную чистоту автомобильных стёкол и бесперебойную работу на протяжении 2-3 сезонов.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ AERODYNAMIC:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: -12,
                            right: 82,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Одни из лидеров рынка по соотношению цены, функциональности и долговременности эксплуатации.',
                                    'Широкий ассортимент размеров (300-700 мм) позволяет подобрать оптимальный вариант практически на любую машину.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Отличаются плотным контактом с лобовым стеклом, что полностью исключает разводы при движении автомобиля на любой даже самой высокой скорости.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Износоустойчивый металлический корпус покрыт специальными антикоррозийными составами.',
                                    'Долговечные резинки производятся из каучука с нанесением графитового покрытия.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Конструкция щеток Avantech Aerodynamic имеет отличную подвижность всех элементов каркаса. Тут применяется винтовой тип соединения элементов вместо более дешевых заклепок. Щетки многих производителей с креплением деталей каркаса традиционными заклепками часто имеет проблемы с заеданием.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '12.5em'
                },
                templates: [
                    {
                        image: null,
                        title: 'AERODYNAMIC HD',
                        description: 'Уникальность серии HD заключается в специальном рельефе резинки, благодаря которому щетка эффективнее удаляет загрязнения (грязь, насекомые).'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '15em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -21.4,
                    top: -180,
                    right: -100,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'ЗИМНИЕ ЩЕТКИ<br><b>SNOWGUARD</b> и <br><b>SNOWGUARD plus</b>',
                image: 'wipers-snowguard-w-plus.png',
                description: 'Самая популярная серия в ассортименте щёток AVANTECH.Именно Зимние щетки AVANTECH SNOWGUARD представляют собой специальную разработку с учетом всех особенностей российских климатических условий. Они рассчитаны для работы в самые суровые морозы, а также при метелях и сильных снегопадах. Поэтому в экстремальных ситуациях щетки серии AVANTECH SNOWGUARD — вне конкуренции.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ SNOWGUARD:',
                        templates: [
                            {
                                collapse: 1,
                                pseudo: {
                                    type: 'mountains',
                                    index: 0,
                                    rotate: 0,
                                    top: 22,
                                    right: 20,
                                    scaleX: 1,
                                    scaleY: 1 
                                },
                                content: [
                                    'Морозоустойчивая резина, выдерживающая до -40°C.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Каркас защищён резиновым чехлом, предотвращающих попадание снега и воды внутрь.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Антикоррозийное покрытие каркаса.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Состав рабочей поверхности Avantech Snowguard разработан, чтобы резиновая кромка оставалась мягкой и эластичной при низких температурах, благодаря чему значительно усиливается очищающая способность щеток.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '12.5em'
                },
                templates: [
                    {
                        image: null,
                        title: 'SNOWGUARD plus',
                        description: 'По сравнению с обычными Snowguard, щетки серии Snowguard Plus включают в свой комплект все необходимые коннекторы, что позволяет использовать их на 99% автомобилей.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '13em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 165.3,
                    top: -250,
                    right: -75,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'БЕСКАРКАСНЫЕ ЩЕТКИ<br><b>SMART NEO</b>',
                image: 'wipers-smart-neo.png',
                description: 'SMART NEO имеют широкую применяемость креплений на 95% автомобилей производства Японии, Кореи, США, Европы и России. Современные технологии производства обеспечивают равномерное нажатие щётки вне зависимости от кривизны стекла.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ SMART NEO:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 12,
                            right: 55,
                            scaleX: 0.6,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Бескаркасные щётки AVANTECH идеально подходят для всесезонного использования.',
                                    'Универсальность использования на автомобилях с левым и правым расположением руля.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Специальный состав резины обеспечивает эффективную работу щётки в широком диапазоне температур.',
                                    'Снижение риска появления разводов за счёт равномерного давления на стекло.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Использование натуральной резины и графитового покрытия обеспечивает бесшумную работу и идеальное удаление влаги.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Спойлер и монолитность конструкции способствуют отличному прилеганию к лобовому стеклу даже на высокой скорости.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -250,
                    right: 105,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'ЩЕТКИ ДЛЯ ЗАДНЕГО СТЕКЛА<br><b>REAR</b> и <b>SNOWGUARD REAR</b>',
                image: 'wipers-smart-neo.png',
                description: ''
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ REAR:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Доступная замена оригинальной щётки заднего стекла.',
                                    'Конструкция щётки AVANTECH REAR аналогична оригинальной.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Графитовое покрытие резинки обеспечивает бесшумную работу щетки и долговечность.',
                                    'Совместимость со всеми популярными типами креплений пластиковых щёток.'
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ SNOWGUARD REAR:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 90,
                            right: 5,
                            scaleX: 1,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Резиновые элемнты щетки выполнены из специального мягкого материала, который не застывает на морозе и прекрасно очищает стекло.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Все подвижные части щетки накрыты непроницаемым для снега, льда и воды чехлом из прочной и эластичной резины.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    },
};