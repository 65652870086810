export const kr = {
    brakes: {
        description: `
            AVANTECH 브레이크 패드는 HYUNDAI, KIA MOTORS, RENAULT SAMSUNG, FUSO를 포함한 대형 자동차 제조업체의 컨베이어 OEM 공급업체인 한국의 현대식 제조 공장에서 제조됩니다.
            <br><br>
            AVANTECH 브레이크 디스크는 대만에 있는 파트너 공장에서 제조됩니다. OEM 제조업체와의 장기적인 협력은 이 공장 제품의 품질 수준을 확인하고 파트너 공장이 세계 최고의 자동차 제조업체(Toyota, Honda, Nissan)에 순정 예비 부품을 공급할 수 있는 자격을 부여합니다.
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em',
            //         'z-index': '1',
            //         'position': 'relative'
            //     },
            //     pseudo: {
            //         type: 'lines-through',
            //         index: 0,
            //         rotate: 3.65,
            //         top: 30,
            //         right: -95,
            //         scaleX: .8,
            //         scaleY: .8 
            //     },
            //     videos: [
            //         {
            //             link: 'ogNBjgUcqO0',
            //             title: 'причины скрипа <br>тормозных колодок',
            //             description: 'Почему скрипят тормозные колодки? Рассказываем самые частые, но не очевидные причины скрипа.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'padding-bottom': '12em'
                },
                title: '<b>브레이크 패드</b>',
                image: 'brake-pads.png',
                description: 'AVANTECH는 모든 속도와 날씨에서 자동차의 안전을 보장하는 운전자 디스크 및 드럼 브레이크 패드를 제공합니다.'
            },
            {
                type: 'product-type',
                styles: {
                    'margin-bottom': '5em',
                    'padding': '10em 0'
                },
                image: 'brake-shoes.png',
                imageStyles: {
                    'top': '0'
                },
                description: '브레이크 패드 AVANTECH는 높은 기술적 특성을 가지고 있으며 부드럽고 효율적인 제동을 제공합니다. ECE-R90의 요구 사항을 충족합니다. 유럽 제품 품질 표준은 브레이크 패드의 성능 및 원래 부품 준수 여부에 대한 일련의 테스트 후에 발행됩니다.'
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        title: '과열 저항',
                        description: '전문적인 브레이크 패드 선택의 핵심 요소는 넓은 온도 범위에서 마찰재의 안정성, 과열에 대한 내성입니다.<br><br>AVANTECH 브레이크 패드의 마찰재는 원래의 것과 유사합니다. 신차용 브레이크 패드 생산.'
                    },
                    {
                        title: ' ',
                        description: '정상 모드에서 디스크와 접촉하는 브레이크 패드의 가열 온도는 +100 - 200°C이며 긴 내리막길을 주행하는 동안 온도 부하는 +350 - 400°C까지 증가합니다.'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: '브레이크 슈 기능:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 5,
                            right: 79,
                            scaleX: .7,
                            scaleY: .7 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '다양한 교통 모드에서 안정적인 제동 특성.',
                                    '브레이크 디스크에 대한 공격적이지 않은 효과 - 결과적으로 브레이크 디스크의 작고 균일한 마모.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'OEM 표준에 따른 패드의 기계적 강도.',
                                    '마찰 먼지로부터 효과적인 표면 청소를 위한 설계.',
                                    '패드 구성에 석면이 없습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '주요 디스크 브레이크 패드 제품군에는 끽끽 소리 방지 플레이트가 장착되어 있습니다.',
                                    '최대 400 °C의 온도 범위',
                                    '마찰 계수 0.35-0.48µ'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '디스크 브레이크 패드용 무상 끽끽 소리 방지 윤활제 포함(생산국 - 한국).',
                                    '적절한 시스템을 사용하는 자동차 모델용 전자 마모 센서.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 178.2,
                    top: -85,
                    right: -10,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>브레이크 디스크 및 드럼</b>',
                image: 'brake-rotor.png',
                description: '제동 시스템은 자동차의 능동적 안전을 보장하는 가장 중요한 수단입니다. 최근 디스크식 브레이크 시스템이 보편화되고 있음에도 불구하고 자동차 제조사들은 여전히 리어 액슬에 드럼 브레이크를 사용하고 있다. 드럼형 시스템에서 제동은 회전하는 드럼에 대해 브레이크 패드를 눌러 수행됩니다. 브레이크 디스크 및 드럼 AVANTECH는 높은 기술적 특성을 가지고 있으며 브레이크 시스템에 대한 OEM 제조업체의 모든 요구 사항을 충족합니다. 디스크와 드럼의 허브는 고온을 견디고 부식에 대한 높은 수준의 보호를 보장하는 특수 페인트로 칠해져 있습니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: '브레이크 디스크 기능:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: -1,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 제품은 탄소 함량이 높은 특수 주철 합금으로 만들어집니다. 이러한 디스크는 자동차 대회에서 선수들이 성공적으로 사용합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'STOP 및 GO 디스크 표면 처리 기술을 사용하면 패드가 있는 디스크의 래핑 시간이 단축됩니다. STOP 및 GO는 점착 계수를 높이고 첫 번째 제동 후 새 브레이크 패드가 빠르게 자리잡도록 합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '최적의 열 전도성은 변형에 대한 저항력을 증가시키고 과열 시 디스크의 파손을 방지합니다. 디스크 디자인은 진동을 제거하고 제동 소음을 줄입니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 브레이크 디스크 생산에 READY TO GO 기술을 사용하면 자동차 소유자가 사용하기 전에 디스크 표면에서 보호 오일 층을 제거할 필요가 없습니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'overflow': 'hidden',
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 30,
                    top: 55,
                    right: -32,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>천공 브레이크 디스크</b>',
                image: 'brake-rotor-perf.png',
                imageStyles: {
                    'z-index': '3',
                    'right': '15%'
                },
                description: '브레이크 시스템에 스트레스가 가해지는 중형 및 스포츠카에 설치하도록 설계되었습니다. 특별히 설계된 통풍구는 브레이크 패드와 디스크의 접촉점에서 가스와 열을 제거하여 제동 효율을 높입니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: -141.3,
                    top: -300,
                    right: 48,
                    scaleX: 1.2,
                    scaleY: 1.2 
                },
                tables: [
                    {
                        title: '천공 브레이크 디스크의 특징:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 90,
                            top: 16,
                            right: 98,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 제품은 탄소 함량이 높은 특수 주철 합금으로 만들어집니다. 이러한 디스크는 자동차 대회에서 선수들이 성공적으로 사용합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '특수 가공 기술로 패드가 있는 디스크의 연삭 시간을 단축합니다.',
                                    '디스크 디자인은 진동을 제거하고 제동 소음을 줄입니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '최적의 열 전도성은 변형에 대한 저항력을 증가시키고 과열 시 디스크의 파손을 방지합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 브레이크 디스크 생산에 READY TO GO 기술을 사용하면 자동차 소유자가 사용하기 전에 디스크 표면에서 보호 오일 층을 제거할 필요가 없습니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};