export const ru = {
    steering: {
        description: `Avantech Parts Group изготавливает детали подвески и рулевого управления на заводах-производителях запасных частей в Малайзии, Турции и на Тайване. На конвейерах этих заводов производятся как оригинальные, так и аналоговые детали мировых брендов.`,
        videos: [],
        styles: {
            'margin-bottom': '7.5em'
        },
        content: [
            {
                type: 'grid-table',
                styles: {
                    'margin-top': '5em'
                },
                tables: [
                    {
                        title: 'ПОДВЕСКА АВТОМОБИЛЯ ВЫПОЛНЯЕТ СЛЕДУЮЩИЕ ФУНКЦИИ:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 50,
                            scaleX: .6,
                            scaleY: .6
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Соединяет колёса или неразрезные мосты с несущей системой автомобиля — кузовом или рамой.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Передаёт на несущую систему силы и моменты, возникающие при взаимодействии колёс с дорогой.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Обеспечивает требуемый характер перемещения колёс относительно кузова или рамы, а также необходимую плавность хода.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: []
                            }
                        ]
                    }
                ]
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '8em',
                    'min-height': '60px',
                    'z-index': '1',
                    '--lg-min-height': '300px'
                },
                boxStyles: {
                    'background': '#fff'
                },
                box: [
                    {
                        tag: 'b',
                        content: 'В ассортименте AVANTECH представлены:'
                    },
                    {
                        tag: 'p',
                        content: `
                            рулевые наконечники, рулевые тяги,<br>
                            шаровые опоры,<br>
                            стойки стабилизатора и т.д.
                        `
                    },
                    {
                        tag: 'b',
                        content: 'Применяемость:'
                    },
                    {
                        tag: 'p',
                        content: 'японские, корейские, европейские автомобили.'
                    }
                ]
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '12.5em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 93.2,
                    top: -220,
                    right: -177,
                    scaleX: -.9,
                    scaleY: .9
                },
                templates: [
                    {
                        title: 'OEM стандарт',
                        description: 'От состояния подвески зависит безопасность машины на дороге, комфортность передвижения и легкость управления. Не секрет, что до недавнего времени только оригинальная продукция могла гарантировать стабильный уровень качества и безопасности. С появлением продукции AVANTECH у автовладельцев появилась возможность не переплачивать за детали подвески и рулевого управления, отвечающие высоким требованиям и стандартам OEM. Уровень цен на продукцию AVANTECH до 50% ниже по сравнению с продукцией известных корейских и японских производителей в данных категориях.'
                    },
                    {
                        title: 'Контроль качества',
                        description: 'Детали подвески и рулевого управления AVANTECH производятся компанией Avantech Parts Group на современных заводах, специализирующихся на изготовлении деталей для ходовой части и рулевого управления. Высокий спрос обеспечивает сочетание современных технологий и меньших производственных издержек в сравнении с японскими концернами. Процесс производства соответствует требованиям международной системы контроля качества ISO/TS 16949. Продукция прошла серию испытаний, подтверждающих ее использование в сложных климатических условиях России согласно требованиям ГОСТ.'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'ПРЕИМУЩЕСТВА ДЕТАЛЕЙ ПОДВЕСКИ И РУЛЕВОГО УПРАВЛЕНИЯ:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 90,
                            top: 44,
                            right: 91,
                            scaleX: 1.4,
                            scaleY: 1.4
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Разработаны на базе OEM-стандартов качества, предусматривающих значительный запас прочности, надежность и долговечность.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Произведены из прочной стали, предназначенной для эксплуатации в сложных дорожных условиях.',
                                    'Высокое качество обработки металла препятствует возникновению коррозии.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Резиновые части деталей устойчивы к низким температурам до -50°C.',
                                    'Детали укомплектованы необходимым крепежом (гайки, шплинты), что исключает необходимость ранее использованного крепежа.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Защитные чехлы на пыльниках гарантируют их сохранность от механических повреждений во время транспортировки и хранения.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: 100,
                    right: -31,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>РУЛЕВЫЕ НАКОНЕЧНИКИ</b>',
                image: 'steering-rod.png',
                description: 'Это детали рулевого механизма, обеспечивающие подвижность и нужный угол поворота колёс.<br><br>Комфортное управление и безопасность движения обеспечивают все детали рулевого механизма, но чаще всего из строя выходят как раз наконечники. Конструкция рулевых наконечников основывается на сферических шарнирах. На рулевые наконечники действуют большие нагрузки и такие факторы как состояние дорожного покрытия, скорость передвижения, езда по неровностям. Рулевой наконечник - наиболее нагруженная деталь подвески автомобиля. Несвоевременная замена наконечников может привести к неисправности рулевой системы и дорогостоящему ремонту.'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '1',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -95.8,
                    top: 195,
                    right: -55,
                    scaleX: .8,
                    scaleY: .8
                },
                title: '<b>РУЛЕВЫЕ ТЯГИ</b>',
                image: 'steering-rack.png',
                description: 'Элементы системы рулевого управления для передачи усилия от рулевого механизма к колесам на заданный угол. Рулевая тяга состоит из длинного металлического стержня и шарового механизма. Так как рулевая тяга передает импульс от рулевого механизма к колесам, она испытывает постоянные нагрузки, происходит износ шарового механизма. Повреждение рулевой тяги можно заметить по стуку, повышенному износу шин и по неровным движениям колес.'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 1,
                    rotate: 35.5,
                    top: -155,
                    right: -40,
                    scaleX: -1,
                    scaleY: 1
                },
                title: '<b>ШАРОВЫЕ ОПОРЫ</b>',
                image: 'steering-joint.png',
                description: 'Являются креплением колеса к подвеске автомобиля. Позволяет колесу двигаться в горизонтальной плоскости и не дает двигаться в вертикальной. Иными словами, не дает колесу заваливаться набок. Качество шаровой опоры крайне важно. Этот элемент имеет большое значение для управляемости автомобиля. При поломке опоры, колесо может даже отсоединиться от автомобиля. В любом случае, при выходе из строя шаровой опоры, дальнейшее движение автомобиля своим ходом невозможно. Именно поэтому, AVANTECH тщательно подходит к разработке и тестированию своих шаровых опор, добиваясь максимального качества и адаптации к эксплуатации в условиях низких температур и бездорожья.'
            },
            {
                type: 'product',
                styles: {
                    'z-index': '1',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'mountains',
                    index: 0,
                    rotate: 90,
                    top: 15,
                    right: 95,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>СТОЙКИ СТАБИЛИЗАТОРА</b>',
                image: 'steering-link.png',
                description: 'Стабилизатор создан для того, чтобы автомобиль был устойчив на дороге, не кренился и держал траекторию. Стойки стабилизатора соединяют стабилизатор и центральный элемент подвески. Сложно переоценить значение исправных стоек стабилизатора для безопасности вождения. С разбитыми стойками стабилизатора автомобиль теряет свою управляемость и становится менее предсказуемым.<br><br>Стойки стабилизатора AVANTECH делаются на заводах, производящих высококлассную продукцию для большинства популярных автомобилей. Вся продукция проходит тщательное тестирование, как в лабораторных, так и в реальных условиях. Поэтому мы уверены в качестве своей продукции.'
            },
        ]
    }
};