export const ru = {
    belts: {
        description: `Приводные ремни предназначены для приведения в движение навесного оборудования ДВС и насоса охлаждающей жидкости посредством передачи крутящего момента от шкива коленвала. Количество приводных ремней на конкретном автомобиле зависит от конструктивных особенностей ДВС.`,
        videos: [],
        content: [
            {
                type: 'list',
                styles: {
                    'margin-top': '5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: -40,
                    top: 75,
                    right: -25,
                    scaleX: -1,
                    scaleY: 1
                },
                list: [
                    {
                        title: 'Наличие и широкий выбор',
                        content: 'Ассортимент продукции составлен с учетом автопарка всех регионов России. Приводные ремни Avantech предназначены для легковых автомобилей и лёгкого коммерческого транспорта. Всего в перечне продукции более 500 наименований ремней, предназначенных для установки на автомобили японского, корейского, европейского, российского производства.'
                    },
                    {
                        title: 'Производство',
                        content: 'Производство приводных ремней AVANTECH расположено в Индонезии, соответствует высочайшим мировым стандартам. Также продукция завода поставляется на конвейер автопроизводителей, расположенных в азиатском регионе.'
                    },
                    {
                        title: 'Технологии',
                        content: 'Ремни изготавливаются из высококачественного EPDM каучука. При этом технология производства учитывает климатические особенности эксплуатации ремней в России.<br><br>Команда Avantech находится в непрерывном взаимодействии с производством, улучшая характеристики продукции и расширяя ассортимент в соответствии с потребностями российских клиентов.'
                    }
                ]
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '12.5em',
                    'min-height': '30px',
                    'gap': '2.5em'
                },
                boxStyles: {
                    'left': '0'
                },
                box: [
                    {
                        tag: 'b',
                        content: 'Преимущества ремней Avantech:'
                    },
                    {
                        tag: 'li',
                        content: 'Высокая износостойкость'
                    },
                    {
                        tag: 'li',
                        content: 'Длительный срок использования'
                    },
                    {
                        tag: 'li',
                        content: 'Низкий уровень шума'
                    },
                    {
                        tag: 'li',
                        content: 'Низкий уровень растяжения в ходе эксплуатации'
                    },
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '15em 0'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -310,
                    right: -15,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>КЛИНОВЫЕ РЕМНИ</b>',
                image: 'belts-v.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Обычно клиновидные ремни соединяют три основных шкива, расположенные в углах треугольника - это коленвал, генератор и помпа.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '1.25em',
                    'margin-bottom': '11em',
                    'z-index': '2',
                    'position': 'relative',
                    'background': '#FFF'
                },
                tables: [
                    {
                        title: 'Особенности клиновых ремней:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Верхний слой клинового ремня AVANTECH обеспечивает прочность и гибкость, устойчив к воздействию технических жидкостей.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Корд клинового ремня AVANTECH выполнен из устойчивого к растяжению материала – арамида.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Слой прессованной резины обеспечивает прочность клинового ремня AVANTECH.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Нижний слой клинового ремня AVANTECH гасит ударные нагрузки, а также предохраняет ремень от трещин.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 3.6,
                    top: -250,
                    right: -155,
                    scaleX: .8,
                    scaleY: .8 
                },
                title: '<b>ПОЛИКЛИНОВЫЕ<br> (РУЧЕЙКОВЫЕ) РЕМНИ</b>',
                image: 'belts-semi-v.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Обычно клиновидные ремни соединяют три основных шкива, расположенные в углах треугольника - это коленвал, генератор и помпа.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Особенности поликлиновых ремней:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 90,
                            top: 27,
                            right: 90,
                            scaleX: .7,
                            scaleY: .7 
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'Верхний слой поликлинового ремня AVANTECH обеспечивает прочность, придаёт гибкость, устойчив к воздействию технических жидкостей. Специальное покрытие обеспечивает защиту ремня от негативного воздействия высоких температур, появляющихся за счет трения.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Применение прессованной хлоропреновой резины и этиленпропиленового каучука гарантирует продолжительный срок службы ремня, стабильность его формы и максимальное давление на клинья.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Корд из нейлона обеспечивает поликлиновому ремню AVANTECH отличную передачу крутящего момента.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};