export const ru = {
    filters: {
        description: `
            Чистое масло и воздух для двигателя, отсутствие пыли и неприятных запахов в салоне - вот главные задачи, которые с успехом выполняют фильтры AVANTECH. Сотни тысяч автолюбителей уже успели оценить их высокое качество, широчайший ассортимент и привлекательную стоимость. AVANTECH предлагает широкую линейку фильтров для японских, корейских, европейских и американских автомобилей.
        `,
        videos: [],
        content: [
            {
                type: 'video',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '12.5em'
                },
                videos: [
                    {
                        link: 'TukrCtbk9AQ',
                        title: 'Масляные фильтры. <br>Краткий обзор.',
                        description: 'Показываем масляные фильтры Avantech в разборе. Рассказываем, где производятся и какие материалы используются.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding-bottom': '16em',
                    'overflow': 'hidden',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: -85,
                    right: -35,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>МАСЛЯНЫЕ ФИЛЬТРЫ</b>',
                image: 'filters-oil.png',
                imageStyles: {
                    'z-index': '1'
                },
                description: 'Масляные фильтры AVANTECH изготавливаются на заводе, выпускающем фильтры для известных марок ведущих японских и европейских производителей. В производстве фильтров AVANTECH используются материалы финской компании Ahlstrom.<br><br>Предназначены для фильтрации масла от содержащихся в нём элементов, образовавшихся в процессе работы двигателя.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding': '5em 0',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 3.6,
                    top: -900,
                    right: -145,
                    scaleX: .8,
                    scaleY: .8
                },
                image: 'filters-oil-inside.png',
                imageStyles: {
                    'left': '60%',
                    'z-index': '1'
                },
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                tables: [
                    {
                        title: 'Масляные фильтры отличаются неоспоримыми преимуществами:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 13,
                            right: 53.8,
                            scaleX: .95,
                            scaleY: .95
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Конструкция масляного фильтра AVANTECH соответствует оригинальной спецификации заводов-производителей автомобилей, а также OEM-стандартам по показателям герметичности, полноты отсева фильтрующего элемента, давления открытия перепускного клапана.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Противодренажный клапан удерживает масло в фильтре при выключенном двигателе, исключая «масляное голодание» при холодном пуске.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Уплотнительное кольцо фильтра устойчиво к низким температурам, что препятствует протеканию масла в местах соединения масляного фильтра с блоком двигателя.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Герметичность фильтрующего элемента обеспечивает подачу масла в рабочую среду только через фильтрующий материал.',
                                    'Повышенная площадь фильтрующего элемента обеспечивает эффективную фильтрацию масла в двигателе, что увеличивает сервисные интервалы.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '12em 0',
                    'z-index': '3',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 12.6,
                    top: -20,
                    right: 17,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>ВОЗДУШНЫЕ ФИЛЬТРЫ</b>',
                image: 'filters-air.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Воздушный фильтр предназначен для предотвращения попадания в двигатель пыли и других посторонних частиц, наличие которых вызывает преждевременный износ механизма. В основе конструкции воздушного фильтра лежит специальная шторка из пористой бумаги, которая улавливает частицы пыли не только поверхностью, но и по всему объему. При засорении воздушного фильтра снижается мощность автомобиля, увеличивается нагар на деталях двигателя, расход топлива, а также возрастает воздействия на окружающую среду из-за повышенных выбросов углекислого газа.'
            },
            {
                type: 'grid-table',
                styles: {
                    'z-index': '2',
                    'position': 'relative',
                    'padding-top': '5em',
                    'background': '#FFF',
                    'padding-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'Преимущества воздушных фильтров:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 4,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Воздушные фильтры AVANTECH соответствуют оригинальным стандартам качества.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'В производстве используются материалы финской компании Ahlstrom.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Бумажные волокна воздушного фильтра AVANTECH задерживают пыль и посторонние частицы диаметром до 1 микрона.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Места соединения фильтрующего элемента и корпуса фильтра имеют надежные уплотнения, что обеспечивает необходимую герметичность конструкции.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'z-index': '2',
                    'position': 'relative',
                    'padding': '12em 0 16em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: 105,
                    right: -37,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>Салонные ФИЛЬТРЫ</b>',
                image: 'filters-cabin.png',
                description: 'Вентиляционная система автомобиля сама по себе не способна очищать воздух, поступающий в салон, от пыли, выхлопных газов и других вредных веществ, содержащихся в атмосфере. Зачастую это является причиной плохого самочувствия и различных заболеваний. Как минимизировать опасность для человека? Ответ один - использовать качественные салонные фильтры.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding': '12em 0'
                },
                pseudo: {
                    type: 'mountains',
                    index: 0,
                    rotate: 0,
                    top: 20,
                    right: 90,
                    scaleX: 1,
                    scaleY: 1
                },
                title: 'УГОЛЬНЫЕ ФИЛЬТРЫ:',
                image: 'filters-coal.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Не пропускают в салон пыльцу, бактерии, углекислый газ, посторонние запахи и пыль. Рекомендуется устанавливать в крупных городах, индустриальных зонах и т.д. Рекомендуется менять салонный фильтр каждые 10 000 км.'
            },
            {
                type: 'product-type',
                styles: {
                    'margin-bottom': '12.5em',
                    'padding': '12em 0'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 86.4,
                    top: -700,
                    right: -50,
                    scaleX: 0.7,
                    scaleY: -0.7
                },
                title: 'СТАНДАРТНЫЕ ФИЛЬТРЫ:',
                image: 'filters-cabin-standard.png',
                description: 'Очищают воздух от асфальтной, цементной пыли и т.п. Рекомендуется устанавливать в районах с низким транспортным потоком (сельская местность).'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding': '13em 0',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 178.2,
                    top: -140,
                    right: -10,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>ТОПЛИВНЫЕ ФИЛЬТРЫ</b>',
                image: 'filters-fuel.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Топливный фильтр необходим для очистки топлива, поступающего в двигатель через топливную магистраль, от содержащихся в нем примесей и воды. В ассортименте топливных фильтров AVANTECH представлены фильтры, как на бензиновые, так и дизельные двигатели.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Преимущества топливных фильтров:',
                        pseudo: {
                            type: 'waves', 
                            index: 0,
                            rotate: 0,
                            top: 5,
                            right: 77.5,
                            scaleX: .8,
                            scaleY: .5
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'Конструкция каждого топливного фильтра AVANTECH соответствует оригинальной спецификации автомобиля.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Герметичный корпус предотвращает попадание топлива на детали двигателя.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Качество топливного фильтра AVANTECH контролируется на всех этапах производства.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};