export const kr = {
    lamps: {
        description: `
            가격, 품질 및 범위 측면에서 중간 가격대에서 최고의 제안 중 하나입니다. 유럽, 일본, 한국 및 미국 생산의 현대적 함대의 요구를 충족합니다.
            <br><br>
            현대적인 로봇 생산 방식으로 한국에서 독점적으로 생산됩니다. 모든 AVANTECH 램프는 필라멘트 기하학 테스트, 가스 누출 테스트 및 진동 테스트를 통과합니다.
        `,
        videos: [],
        content: [
            {
                type: 'product',
                styles: {
                    'margin': '5em 0',
                    'overflow': 'hidden'
                },
                pseudo: {
                    type: 'lines-cut',
                    index: 0,
                    rotate: 0,
                    top: -35,
                    right: -65,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '최대 백색<br> 세련된 조명<br> <b>NIGHT FIGHTER</b>',
                image: 'lamps-night.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'AVANTECH NIGHT FIGHTER 시리즈 - 청색 전구 코팅 램프. 5000K의 증가된 밝기와 색온도는 현대 자동차 소유자들 사이에서 이러한 램프의 지속적인 인기를 제공합니다. AVANTECH NIGHT FIGHTER 램프는 크세논 광원의 효과로 헤드라이트에 강렬한 흰색 색조를 부여합니다. 도로 상황에 대한 최대 대비와 명확한 세부 사항을 보장합니다. <br><br>러시아 연방 기술 규정의 모든 요구 사항을 완벽하게 준수하므로 램프를 개인용 및 상업용 차량 모두에 표준 조명으로 설치할 수 있습니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'NIGHT FIGHTER 시리즈 특징',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 120,
                            right: 76,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '색온도 5000K - 운전자의 눈에 편안한 시원한 백색광.',
                                    '밤에 도로 헤드라이트의 밝기가 110% 증가합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '+50% 백색광(표준 램프에 비해).',
                                    '증가된 광선은 더 많은 반응 시간을 제공합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '자동차 헤드라이트의 현대적이고 세련된 모습.',
                                    'EAC 및 ECE 인증서.',
                                    '2개 세트 블리스 터 팩에.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '수명 연장을 위한 석영 유리.',
                                    '램프 소켓 9005(HB3) 9006(HB4) H1 H11 H16 H27/1 H3 H4 H7 H8 H9가 장착된 대부분의 최신 차량용 전체 범위.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                title: '비, 안개, 눈 속에서<br> 도로 통제<br> <b>FOG FIGHTER</b>',
                image: 'lamps-fog.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'AVANTECH FOG FIGHTER 시리즈 - 김서림 방지 효과가 있는 램프. 온도가 3000K인 따뜻한 노란색 빛을 발산합니다. 어려운 기후 조건(비, 안개, 눈)에서 표준 램프에 비해 50% 더 밝게 빛납니다. 기본 또는 보조 헤드라이트로 설치할 수 있습니다.<br><br>AVANTECH FOG FIGHTER 램프의 밝은 노란색은 플라스크에 적용된 특수 간섭 코팅으로 제공됩니다. 3000K 색온도는 악천후에서 운전하는 동안 도로의 대비를 향상시킵니다. 사실 강우 중 순수한 흰색 헤드라이트는 작은 물방울이나 눈송이에서 강렬하게 반사되어 운전자의 눈을 멀게 합니다. 이러한 조건에서는 운전자의 눈에 부담을 주지 않고 더 멀리 볼 수 있도록 해주는 노란색 표시등이 더 좋습니다. 또한 악천후에서 AVANTECH FOG FIGHTER 램프가 장착된 차량은 도로에서 훨씬 더 잘 보이므로 사고 위험이 크게 줄어듭니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'FOG FIGHTER 시리즈 특징:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '색온도 3000K - 악천후에 대비한 노란색 조명 램프.',
                                    '안개, 비, 눈 속에서 +50% 밝기.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '부드럽고 편안한 노란색 빛.',
                                    '수명 연장을 위한 석영 유리.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'EAC 및 ECE 인증서',
                                    '2개 세트 블리스 터 팩에.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '램프 소켓 9005(HB3) 9006(HB4) H1 H11 H16 H27/1 H3 H4 H7 H8 H9가 장착된 대부분의 최신 차량용 전체 범위.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: -115,
                    right: -25,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>할로겐 램프</b>',
                image: 'lamps-halogen.png',
                description: 'AVANTECH 할로겐 램프는 수년간의 생산 및 작동을 통해 입증된 클래식 솔루션입니다. 그들은 솜씨, 보증 된 서비스 수명 및 유리한 가격을 최적으로 결합합니다. 일반적으로 이러한 유형의 램프는 많은 자동차에 표준으로 설치되며 유럽 표준에 따라 필수 예비 세트로 사용됩니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: '할로겐 램프의 특징:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: 10,
                            right: 92,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '수명 연장을 위한 석영 유리.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'OEM 표준을 준수합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'EAC 및 ECE 인증서'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '9005(HB3) 램프 베이스가 있는 대부분의 현대식 차량을 위한 완벽한 범위.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding' : '14em 0'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 96.8,
                    top: -350,
                    right: -55,
                    scaleX: 0.7,
                    scaleY: -0.7
                },
                title: '<b>크세논 램프</b>',
                image: 'lamps-hid.png',
                description: '크세논 램프 AVANTECH는 원래 램프의 공장 매개변수를 완전히 준수하며 표준 크세논을 대체하도록 설계되었습니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '1',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: '크세논 램프의 특징:',
                        pseudo: {
                            type: 'marks',
                            index: 1,
                            rotate: 178.2,
                            top: 142,
                            right: -12,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '램프의 색온도는 4300K입니다.',
                                    '크세논 램프의 광속은 표준 할로겐 램프보다 80% 더 큽니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '크세논 램프의 또 다른 주요 이점은 수명입니다. 할로겐 램프보다 최대 5배 더 오래 지속됩니다.'
                                ]
                            },
                            {
                                collapse: 2,
                                content: [
                                    '램프 생산에는 석영 유리, 주요 유럽 제조업체의 몰리브덴 필라멘트 및 일본의 세라믹 튜브와 같은 최신 재료 만 사용됩니다.'
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -55,
                    right: 110,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>LED 전구</b>',
                image: 'lamps-led.png',
                description: 'AVANTECH LED 램프는 첨단 기술의 발전 덕분에 출현이 가능해진 새로운 현대 광원입니다.<br><br>LED 광원은 자동차 램프 산업 발전의 다음 단계가 되었습니다. 이미 친숙한 할로겐 및 크세논 램프와 비교할 때 부인할 수 없는 여러 가지 장점이 있습니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'LED 램프의 특징:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 0,
                            top: 16,
                            right: 91,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '내구성 - 최대 20,000시간.',
                                    '낮은 열 발생 - 플라스틱 헤드라이트에 안전합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '스타일 - 최대 6500K의 밝은 백색광.',
                                    '저전력 소비 - 할로겐 램프보다 최대 2.5배 낮습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '효율성 - 1와트에서 최대 110루멘.',
                                    '안전 - 수은을 포함하지 않으며 UV 스펙트럼에서 방출하지 않습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '생산 100% 한국. LUMIMICRO와 LG INNOTEK LED에서 제조한 LED.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};