export const ru = {
    breadcrumbs: {
        home: { name: 'Главная', to: '/' },
        whereToBuy: { name: 'Где купить', to: '/store' },
        catalog: { name: 'Продукция', to: '/catalog' },
        about: { name: 'О компании', to: '/about'},
        assurance: { name: 'Гарантия', to: '/assurance' },
        inform: { name: 'Информация', to: '/inform'},
        partners: { name: 'Партнерам', to: '/partners'},
        anthers: { name: 'Пыльники', to: '/catalog/anthers'},
        belts: { name: 'Приводные ремни', to: '/catalog/belts'},
        discs: { name: 'Тормозные диски и барабаны', to: '/catalog/discs'},
        filters: { name: 'Фильтры', to: '/catalog/filters'},
        lamps: { name: 'Лампы', to: '/catalog/lamps'},
        brakes: { name: 'Тормозная система', to: '/catalog/brakes'},
        pumps: { name: 'Топливные насосы', to: '/catalog/pumps'},
        rubber: { name: 'Резинотехнические изделия', to: '/catalog/rubber'},
        springs: { name: 'Пружины подвески', to: '/catalog/springs'},
        steering: { name: 'Детали подвески и рулевого управления', to: '/catalog/steering'},
        wipers: { name: 'Щетки стеклоочистителя', to: '/catalog/wipers'},
    },
};
