export const en = {
    belts: {
        description: `Drive belts are designed to drive the ICE attachment and the coolant pump by transmitting torque from the crankshaft pulley. The number of drive belts on a particular vehicle depends on the design features of the internal combustion engine.`,
        videos: [],
        content: [
            {
                type: 'list',
                styles: {
                    'margin-top': '5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: -40,
                    top: 75,
                    right: -25,
                    scaleX: -1,
                    scaleY: 1
                },
                list: [
                    {
                        title: 'Availability and wide selection',
                        content: 'The product range is compiled taking into account the fleet of all regions of Russia. Avantech drive belts are designed for passenger cars and light commercial vehicles. In total, the list of products includes more than 500 types of belts designed for installation on cars of Japanese, Korean, European, Russian production.'
                    },
                    {
                        title: 'Production',
                        content: 'The production of AVANTECH drive belts is located in Indonesia and meets the highest international standards. Also, the plant\'s products are supplied to the assembly line of automakers located in the Asian region.'
                    },
                    {
                        title: 'Technologies',
                        content: 'Belts are made from high quality EPDM rubber. At the same time, the production technology takes into account the climatic features of the operation of belts in Russia.<br><br>The Avantech team is in continuous interaction with production, improving product characteristics and expanding the range in accordance with the needs of Russian customers.'
                    }
                ]
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '12.5em',
                    'min-height': '30px',
                    'gap': '2.5em'
                },
                boxStyles: {
                    'left': '0'
                },
                box: [
                    {
                        tag: 'b',
                        content: 'Advantages of Avantech belts:'
                    },
                    {
                        tag: 'li',
                        content: 'High wear resistance'
                    },
                    {
                        tag: 'li',
                        content: 'Long term use'
                    },
                    {
                        tag: 'li',
                        content: 'Low noise'
                    },
                    {
                        tag: 'li',
                        content: 'Low stretch during operation'
                    },
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '15em 0'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -310,
                    right: -15,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>V-BELTS</b>',
                image: 'belts-v.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Usually, V-BELTS connect three main pulleys located at the corners of the triangle - these are the crankshaft, generator and pump.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '1.25em',
                    'margin-bottom': '11em',
                    'z-index': '2',
                    'position': 'relative',
                    'background': '#FFF'
                },
                tables: [
                    {
                        title: 'Features of V-BELTS:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'The top layer of the AVANTECH V-BELT provides strength and flexibility, and is resistant to technical fluids.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The cord of AVANTECH V-BELT is made of aramid, resistant to stretching material.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The layer of pressed rubber ensures the strength of the AVANTECH V-BELT.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The bottom layer of the AVANTECH V-BELT absorbs shock loads and also protects the belt from cracking.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 3.6,
                    top: -250,
                    right: -155,
                    scaleX: .8,
                    scaleY: .8 
                },
                title: '<b>POLY V-BELTS <br>(RICH) BELTS</b>',
                image: 'belts-semi-v.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Usually, V-BELTS connect three main pulleys located at the corners of the triangle - these are the crankshaft, generator and pump.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Features of poly V-BELTS:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 90,
                            top: 27,
                            right: 90,
                            scaleX: .7,
                            scaleY: .7 
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'The top layer of the AVANTECH V-ribbed belt provides strength, gives flexibility, and is resistant to technical fluids. A special coating protects the belt from the negative effects of high temperatures that appear due to friction.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The use of molded chloroprene rubber and EPDM guarantees long belt life, dimensional stability and maximum wedge pressure.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The nylon cord provides the AVANTECH V-ribbed belt with excellent torque transmission.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};