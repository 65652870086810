<template>
    <ul ref="accordion" class="advice-detail" uk-accordion="multiple: true">
        <li>
            <div class="detail-title">
                <input id="title-checkbox" class="title-checkbox" type="checkbox" v-model="checkedAll" @click="toggleAll" />
                <a class="uk-accordion-title" @click="toggleAccordion">{{ title }}</a>
            </div>

            <div class="detail-content uk-accordion-content">
                <label v-for="(item, index) in values" :key="index" class="content-item">
                    <input :ref="setRefDetail" class="detail-checkbox" type="checkbox" @click="checkAll" />
                    {{ item }}
                </label>
            </div>
        </li>
    </ul>
</template>

<script setup>
import { ref, watch } from "vue";
import UIkit from "uikit";

const emit = defineEmits(["get:detail"]);

const $props = defineProps({
    title: null,
    values: [],
    clear: 0,
});

const accordion = ref(null);
const checkedAll = ref(false);
const refDetails = ref(new Array());

watch(() => $props.clear,
    (value) => {
        clearDetails();
    }
);

function checkAll() {
    checkedAll.value = refDetails.value.every((detail) => {
        return detail.checked;
    });
    emitCheckedDetails();
}

function toggleAll(event) {
    refDetails.value.forEach((detail) => {
        detail.checked = event.target.checked;
    });
    emitCheckedDetails();
}

function toggleAccordion() {
    UIkit.accordion(accordion.value).toggle();
}

function setRefDetail(element) {
    if (element) {
        refDetails.value.push(element);
    }
}

function clearDetails() {
    refDetails.value.forEach((detail) => {
        detail.checked = false;
    });
    checkedAll.value = false;
}

function emitCheckedDetails() {
    const checked = $props.values.filter((value, index) => {
        return refDetails.value[index].checked;
    });

    emit("get:detail", checked);
}
</script>

<style lang="scss" scoped>
@import "@assets/sass/components/advice-detail.sass";
</style>
