export const kr = {
    wipers: {
        description: `
            특히 비, 진창으로 시야가 악화되거나 와이퍼 블레이드에 줄무늬가 생기기 시작하면 앞유리의 일부 영역을 건너뛰고 물 줄무늬를 남기면 와이퍼 블레이드를 교체할 때입니다.
            <br><br>
            AVANTECH 와이퍼 블레이드는 여름, 겨울 및 사계절 작동을 위해 설계된 모든 유형의 디자인으로 대표됩니다. 누구나 국산차와 국산차 모두에 적합한 모델을 선택할 수 있습니다.
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em'
            //     },
            //     videos: [
            //         {
            //             link: 'v7sxH7sGxfE',
            //             title: 'Виды щеток стеклоочистителя',
            //             description: 'Показываем какие бывают дворники, в чем их отличия и особенности каждого вида на примере дворников Avantech.'
            //         },
            //         {
            //             link: 'KS1Ul-C-2t8',
            //             title: 'Замена лезвий на щетках стеклоочистителя',
            //             description: 'В этом видео мы покажем, как сменить резиновые лезвия на каркасных, бескаркасных и гибридных щетках стеклоочистителя Avantech.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '5em',
                    'overflow': 'hidden'
                },
                title: '<b>HYBRID PLUS</b><br>브러쉬',
                image: 'wipers-hybrid-plus.png',
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -90,
                    right: -34,
                    scaleX: 1,
                    scaleY: 1 
                },
                description: '이 라인은 두 가지 유형의 와이퍼 블레이드를 결합하여 좌우 운전이 가능한 차량용입니다. 하이브리드 브러시에서 공기 역학적 프레임은 비대칭 구조를 가집니다. 따라서 핸들 위치가 다른 차량의 경우 전용 브러시를 사용해야 합니다.'
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        image: 'wipers-right-hd.png',
                        title: 'Right hand drive',
                        description: '오른 손잡이 차량용 AVANTECH HYBRID PLUS 브러시에는 HR-16, HR-18 등과 같은 품목 번호가 있습니다. 크기 수 - 13(300~700mm).'
                    },
                    {
                        image: 'wipers-left-hd.png',
                        title: 'Left hand drive',
                        description: '왼쪽 핸들 차량용 AVANTECH HYBRID PLUS 브러시에는 HL-16, HL-18 등과 같은 품목 번호가 있습니다. 크기 수 - 13(300~700mm).'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'HYBRID WIPER BENEFITS:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '세련된 브러시 디자인은 현대 자동차의 기술적 외관을 보완합니다.',
                                    'OEM 표준에 따른 브러시 설계 및 제조.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '고무 요소의 우수한 품질과 브러시 프레임에 대한 강력한 고정.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '유리 섬유 함량이 45%인 재료를 프레임의 기초로 사용하여 긴 사용 수명, 구조적 안정성 및 유리에 대한 브러시의 균일한 압력을 보장합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '고무의 특수 구성은 광범위한 온도에서 브러시의 효율적인 작동을 보장합니다.',
                                    '커넥터는 9x3(표준) 및 9x4(와이드)의 두 가지 유형의 후크에 맞도록 설계되었습니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '12em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 66.4,
                    top: -120,
                    right: 80,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '범용 브러시<br><b>AERODYNAMIC</b> 및 <br><b>AERODYNAMIC HD</b>',
                image: 'wipers-aerodynamic-w-hd.png',
                description: '프레임(범용) 와이퍼 블레이드는 지난 수십 년간의 생산 및 엔지니어링 개발 경험을 흡수했습니다. 현대적인 소재와 고전적인 장치의 조합은 자동차 창문의 최대 청결과 2-3계절 동안 중단 없는 작동을 보장합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'AERODYNAMIC 와이퍼 브러시의 이점:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: -12,
                            right: 82,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '가격, 기능 및 내구성 측면에서 시장 리더 중 하나입니다.',
                                    '다양한 크기(300-700mm)로 거의 모든 기계에 가장 적합한 옵션을 선택할 수 있습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '그들은 자동차가 최고 속도로 움직일 때 줄무늬를 완전히 제거하는 앞 유리와의 긴밀한 접촉으로 구별됩니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '내마모성 금속 케이스는 특수 부식 방지 화합물로 코팅되어 있습니다.',
                                    '내구성 있는 고무 밴드는 흑연 코팅이 된 고무로 만들어집니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Avantech Aerodynamic 브러시의 디자인은 모든 프레임 요소의 이동성이 뛰어납니다. 여기서는 값싼 리벳 대신 나사 유형의 요소 연결이 사용됩니다. 전통적인 리벳 프레임 부품을 사용하는 많은 제조업체의 브러시는 종종 달라붙는 문제가 있습니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '12.5em'
                },
                templates: [
                    {
                        image: null,
                        title: 'AERODYNAMIC HD',
                        description: 'HD 시리즈의 독창성은 브러시가 먼지(먼지, 벌레)를 보다 효율적으로 제거하는 덕분에 잇몸의 특별한 완화에 있습니다.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '15em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -21.4,
                    top: -200,
                    right: -100,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '윈터 브러쉬<br><b>SNOWGUARD</b> 및 <br><b>SNOWGUARD plus</b>',
                image: 'wipers-snowguard-w-plus.png',
                description: 'AVANTECH 브러시 모음에서 가장 인기 있는 시리즈입니다. 러시아 기후 조건의 모든 특징을 고려하여 특별히 개발된 것은 AVANTECH SNOWGUARD 겨울용 브러시입니다. 눈보라와 폭설뿐만 아니라 가장 심한 서리에서도 작동하도록 설계되었습니다. 따라서 극한 상황에서 AVANTECH SNOWGUARD 시리즈 브러시는 경쟁에서 벗어났습니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'SNOWGUARD 와이퍼 브러시의 장점:',
                        templates: [
                            {
                                collapse: 1,
                                pseudo: {
                                    type: 'mountains',
                                    index: 0,
                                    rotate: 0,
                                    top: 30,
                                    right: 20,
                                    scaleX: 1,
                                    scaleY: 1 
                                },
                                content: [
                                    '-40°C까지 견딜 수 있는 서리 방지 고무.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '프레임워크는 내부에 눈과 물이 닿지 않도록 고무 덮개로 보호됩니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '프레임의 부식 방지 코팅.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Avantech Snowguard는 저온에서 고무 가장자리를 부드럽고 유연하게 유지하도록 제조되어 브러시의 청소력을 크게 향상시킵니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '12.5em'
                },
                templates: [
                    {
                        image: null,
                        title: 'SNOWGUARD plus',
                        description: '기존 스노우가드 대비 스노우가드 플러스 시리즈는 필요한 커넥터가 모두 포함되어 있어 99%의 차량에 사용하기에 적합합니다.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '13em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 165.3,
                    top: -300,
                    right: -75,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '액자 브러쉬<br><b>SMART NEO</b>',
                image: 'wipers-smart-neo.png',
                description: 'SMART NEO 마운트는 일본, 한국, 미국, 유럽 및 러시아에서 제조된 자동차의 95%에 널리 사용됩니다. 최신 생산 기술은 유리의 곡률에 관계없이 균일한 브러시 압력을 보장합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'SMART NEO 와이퍼 브러시의 장점:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 12,
                            right: 55,
                            scaleX: 0.6,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 프레임리스 브러시는 전천후 사용에 이상적입니다.',
                                    '좌우 핸들이 있는 차량에서 다용도로 사용할 수 있습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '고무의 특수 구성은 광범위한 온도에서 브러시의 효율적인 작동을 보장합니다.',
                                    '유리에 가해지는 균일한 압력으로 인해 줄무늬가 생길 위험이 줄어듭니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '천연고무와 흑연코팅을 사용하여 정숙한 작동과 완벽한 수분제거를 보장합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '스포일러와 견고한 구조는 고속에서도 앞유리에 완벽하게 밀착됩니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -250,
                    right: 105,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '후방 창 <b>REAR</b> 및 <br> <b>SNOWGUARD REAR</b> 용 브러시',
                image: 'wipers-smart-neo.png',
                description: ''
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'REAR 와이퍼 브러시의 이점:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '원래 뒷유리 와이퍼를 저렴한 가격으로 교체할 수 있습니다.',
                                    'AVANTECH REAR 브러시의 디자인은 원본과 유사합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '고무의 흑연 코팅은 조용한 브러시 작동과 내구성을 보장합니다.',
                                    '모든 대중적인 유형의 플라스틱 브러시 부착물과 호환됩니다.'
                                ]
                            }
                        ]
                    },
                    {
                        title: 'SNOWGUARD REAR 와이퍼 브러시의 장점:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 90,
                            right: 5,
                            scaleX: 1,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '브러시의 고무 요소는 추위에 얼지 않고 유리를 완벽하게 청소하는 특수 부드러운 소재로 만들어졌습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '브러시의 모든 움직이는 부분은 눈, 얼음 및 물에 영향을 받지 않는 내구성 있고 탄력 있는 고무로 만든 덮개로 덮여 있습니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    },
};