<template>
    <div class="advice-form">
        <div class="advice-form-header">Подбор запчастей через консультанта</div>
        <div class="advice-form-body scrollbar scrollbar-rounded">
            <div v-for="(fields, index) in advice.form" :key="'fields-' + index" class="advice-form-fields">
                <div v-for="(input, ind) in fields" :key="'field-' + ind" :class="['field', { 'field-invalid': invalid.fields && invalid.fields[input.id] }]">
                    <input
                        :id="input.id"
                        :type="input.type"
                        :name="input.id"
                        class="uk-input"
                        @focus="activeInput = input.id"
                        @blur="activeInput = null"
                        v-model="formData[input.id]"
                    />
                    <label :for="input.id" :class="['placeholder', { placeholder_top: isPlaceholderTop(input.id) }]">
                        {{ input.placeholder }}
                    </label>
                </div>
            </div>

            <div id="form-details" class="advice-form-details">
                <label for="form-details" class="details-label">Выберите необходимые детали</label>
                <div v-for="(details, index) in advice.details" :key="'details-' + index" class="field details-field">
                    <AdviceDetail
                        v-for="(detail, ind) in details"
                        :key="'detail-' + ind"
                        :title="detail.title"
                        :values="detail.values"
                        :clear="clearDetails"
                        @get:detail="(value) => getDetail('' + index + ind, detail.title, value)"
                    />
                </div>
            </div>
        </div>

        <div class="advice-confirm">
            <button class="button button-primary button-md button-solid" :disabled="isSendDisabled" @click="submitRequest">
                <span>Отправить</span>
            </button>

            <div class="feedback-message message-success" v-if="success">
                <strong>
                    <span>Готово!</span>
                    Сообщение успешно отправлено.
                </strong>
            </div>

            <div class="feedback-message message-error" v-if="!success && invalid.message && invalid.message.length > 0">
                <strong>
                    <span>Произошла ошибка! </span>
                    <p v-for="(message, index) in invalid.message" :key="index">{{ message }}</p>
                </strong>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from "axios";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useReCaptcha } from "vue3-usf-feedback-form";
import AdviceDetail from "@components/AdviceDetail.vue";

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

const $store = useStore();

const success = ref(false);
const activeInput = ref(null);
const clearDetails = ref(0);
const advice = ref($store.getters["static/getAdvice"]);

const invalid = ref({ fields: {}, message: [] });
const formData = ref({ details: {} });

watch(
    () => formData.value.email,
    (newV, oldV) => {
        if (newV !== oldV) {
            invalid.value.fields.email = false;
        }
    }
);

watch(
    () => formData.value.vin,
    (newV, oldV) => {
        if (newV.length > 17) {
            formData.value.vin = oldV;
        }
    }
);

const isSendDisabled = computed(() => {
    for (const req of advice.value.required) {
        if (!formData.value[req]) return true;
        if (typeof formData.value[req] === "object" && formData.value[req] !== null) {
            if (Object.values(formData.value[req]).length === 0) return true;
        }
        if (formData.value[req] === 0) return true;
        if (formData.value[req].length === 0) return true;
    }
    return false;
});

function isPlaceholderTop(id) {
    if (activeInput.value === id || (formData.value[id] && formData.value[id].length)) return true;
    return false;
}

function getDetail(index, title, values) {
    if (values.length) {
        formData.value.details[index] = { title, values };
    } else {
        delete formData.value.details[index];
    }
}

function submitRequest() {
    if (isSendDisabled.value) return;
    
    success.value = false;
    invalid.value.fields = {};
    invalid.value.message = [];

    if (!validateEmail(formData.value.email)) {
        invalid.value.fields.email = true;
        invalid.value.message.push('Указан неверный e-mail.');
    }

    if (invalid.value.message.length > 0) {
        return
    }

    recaptcha();
}

const advicePost = import.meta.env.VITE_APP_SERVICES_API_URL + "/post-advice-form";

async function postAdviceForm(values) {
    let status = false;
    isSendDisabled.value = true;

    await axios.post(advicePost, values).then((response) => {
        status = response.data.success;
        if (response.data.success) {
            success.value = true;
            const pht = document.querySelectorAll(".placeholder_top");
            pht?.forEach((p) => p.classList.remove("placeholder_top"));
            clearFormData();
            return;
        }
        invalid.value.fields = response.data.inputs;
        invalid.value.message = response.data.message;
    });

    isSendDisabled.value = false;
    return status;
}

async function recaptcha() {
    await recaptchaLoaded();
    formData.value["token"] = await executeRecaptcha("login");
    postAdviceForm(formData.value);
}

function clearFormData() {
    Object.keys(formData.value).forEach((key) => {
        if (typeof formData.value[key] === "object" && formData.value[key] !== null) {
            formData.value[key] = {};
        } else {
            formData.value[key] = null;
        }
    });
    clearDetails.value++;
}

function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
</script>

<style lang="scss" scoped>
@import "@assets/sass/components/advice-form.sass";
</style>
