export const en = {
    wipers: {
        description: `
            If visibility has deteriorated, especially in rain, slush, or the wiper blades have begun to leave streaks, skip some areas of the windshield and leave streaks of water, then it's time to replace the wiper blades.
            <br><br>
            AVANTECH wiper blades are represented by all types of designs designed for summer, winter and all-season operation. Anyone can choose a suitable model for both foreign and domestic cars.
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em'
            //     },
            //     videos: [
            //         {
            //             link: 'v7sxH7sGxfE',
            //             title: 'Виды щеток стеклоочистителя',
            //             description: 'Показываем какие бывают дворники, в чем их отличия и особенности каждого вида на примере дворников Avantech.'
            //         },
            //         {
            //             link: 'KS1Ul-C-2t8',
            //             title: 'Замена лезвий на щетках стеклоочистителя',
            //             description: 'В этом видео мы покажем, как сменить резиновые лезвия на каркасных, бескаркасных и гибридных щетках стеклоочистителя Avantech.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '5em',
                    'overflow': 'hidden'
                },
                title: '<b>HYBRID PLUS</b><br>BRUSHES',
                image: 'wipers-hybrid-plus.png',
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -90,
                    right: -34,
                    scaleX: 1,
                    scaleY: 1 
                },
                description: 'This line combines two types of wiper blades - for vehicles with right and left hand drive. Recall that in hybrid brushes, the aerodynamic frame has an asymmetric structure. Accordingly, for cars with different steering wheel positions, you must use your own brush.'
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        image: 'wipers-right-hd.png',
                        title: 'Right hand drive',
                        description: 'AVANTECH HYBRID PLUS brushes for right-hand drive vehicles have article numbers like HR-16, HR-18, etc. Number of sizes - 13 (from 300 to 700 mm).'
                    },
                    {
                        image: 'wipers-left-hd.png',
                        title: 'Left hand drive',
                        description: 'AVANTECH HYBRID PLUS brushes for left-hand drive vehicles have article numbers like HL-16, HL-18, etc. Number of sizes - 13 (from 300 to 700 mm).'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'HYBRID WIPER BENEFITS:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'The stylish brush design complements the technological exterior of modern cars.',
                                    'Design and manufacture of brushes according to OEM standards.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Excellent quality of the rubber element and its strong fixation in the brush frame.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The use of a material with 45% fiberglass content as the basis for the frame guarantees a long service life, structural reliability, and uniform pressure of the brush to the glass.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The special composition of rubber ensures efficient operation of the brush in a wide range of temperatures.',
                                    'The connector is designed to fit two types of hooks: 9x3 (standard) and 9x4 (wide).'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '12em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 66.4,
                    top: -120,
                    right: 80,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'universal BRUSHES<br><b>AERODYNAMIC</b> and <br><b>AERODYNAMIC HD</b>',
                image: 'wipers-aerodynamic-w-hd.png',
                description: 'Frame (universal) wiper blades have absorbed the world experience of the last decades of production and engineering developments. The combination of modern materials and a classic device guarantees maximum cleanliness of car windows and uninterrupted operation for 2-3 seasons.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'Benefits of AERODYNAMIC WIPER BRUSHES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: -12,
                            right: 82,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'One of the market leaders in terms of price, functionality and durability.',
                                    'A wide range of sizes (300-700 mm) allows you to choose the best option for almost any machine.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'They are distinguished by tight contact with the windshield, which completely eliminates streaks when the car is moving at any even the highest speed.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The wear-resistant metal case is coated with special anti-corrosion compounds.',
                                    'Durable rubber bands are made from rubber with a graphite coating.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The design of Avantech Aerodynamic brushes has excellent mobility of all frame elements. Here, a screw type of connection of elements is used instead of cheaper rivets. Brushes from many manufacturers with traditional riveted frame parts often have sticking problems.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '12.5em'
                },
                templates: [
                    {
                        image: null,
                        title: 'AERODYNAMIC HD',
                        description: 'The uniqueness of the HD series lies in the special relief of the gum, thanks to which the brush removes dirt (dirt, insects) more efficiently.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '15em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -21.4,
                    top: -180,
                    right: -100,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'WINTER BRUSHES<br><b>SNOWGUARD</b> and <br><b>SNOWGUARD plus</b>',
                image: 'wipers-snowguard-w-plus.png',
                description: 'The most popular series in the assortment of AVANTECH brushes. It is the AVANTECH SNOWGUARD winter brushes that are a special development, taking into account all the features of Russian climatic conditions. They are designed to work in the most severe frosts, as well as during blizzards and heavy snowfalls. Therefore, in extreme situations, AVANTECH SNOWGUARD series brushes are beyond competition.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'Benefits of SNOWGUARD WIPER BRUSHES:',
                        templates: [
                            {
                                collapse: 1,
                                pseudo: {
                                    type: 'mountains',
                                    index: 0,
                                    rotate: 0,
                                    top: 30,
                                    right: 20,
                                    scaleX: 1,
                                    scaleY: 1 
                                },
                                content: [
                                    'Frost-resistant rubber that can withstand up to -40°C.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The framework is protected by a rubber cover preventing hit of snow and water inside.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Anti-corrosion coating of the frame.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Avantech Snowguard is formulated to keep the rubber edge soft and supple at low temperatures, greatly enhancing the cleaning power of the brushes.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '12.5em'
                },
                templates: [
                    {
                        image: null,
                        title: 'SNOWGUARD plus',
                        description: 'Compared to conventional Snowguards, the Snowguard Plus series includes all the necessary connectors, making it suitable for use in 99% of vehicles.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '13em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 165.3,
                    top: -270,
                    right: -75,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'FRAMED BRUSHES<br><b>SMART NEO</b>',
                image: 'wipers-smart-neo.png',
                description: 'SMART NEO mounts are widely used in 95% of cars made in Japan, Korea, USA, Europe and Russia. Modern production technologies ensure uniform brush pressure regardless of the curvature of the glass.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'Advantages of SMART NEO WIPER BRUSHES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 12,
                            right: 55,
                            scaleX: 0.6,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH frameless brushes are ideal for all-weather use.',
                                    'Versatile use on vehicles with left and right hand drive.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The special composition of rubber ensures efficient operation of the brush in a wide range of temperatures.',
                                    'Reducing the risk of streaks due to uniform pressure on the glass.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The use of natural rubber and graphite coating ensures quiet operation and perfect moisture removal.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The spoiler and solid construction contribute to an excellent fit to the windshield even at high speed.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -250,
                    right: 105,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'BRUSHES FOR REAR WINDOW<br><b>REAR</b> and <b>SNOWGUARD REAR</b>',
                image: 'wipers-smart-neo.png',
                description: ''
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Benefits of REAR WIPER BRUSHES:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Affordable replacement for the original rear window wiper.',
                                    'The design of the AVANTECH REAR brush is similar to the original.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The graphite coating on the rubber ensures quiet brush operation and durability.',
                                    'Compatible with all popular types of plastic brush attachments.'
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Benefits of SNOWGUARD REAR WIPER BRUSHES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 90,
                            right: 5,
                            scaleX: 1,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'The rubber elements of the brush are made of a special soft material that does not freeze in the cold and perfectly cleans the glass.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'All moving parts of the brush are covered with a cover made of durable and elastic rubber, impervious to snow, ice and water.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    },
};