export const kr = {
    filters: {
        description: `
        엔진의 깨끗한 오일과 공기, 실내 먼지 및 불쾌한 냄새의 부재 - 이것이 AVANTECH 필터가 성공적으로 수행하는 주요 작업입니다. 수십만 명의 운전자가 이미 고품질, 가장 넓은 범위 및 매력적인 가격에 감사했습니다. AVANTECH는 일본, 한국, 유럽 및 미국 차량을 위한 다양한 필터를 제공합니다.
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em'
            //     },
            //     videos: [
            //         {
            //             link: 'TukrCtbk9AQ',
            //             title: 'Масляные фильтры. <br>Краткий обзор.',
            //             description: 'Показываем масляные фильтры Avantech в разборе. Рассказываем, где производятся и какие материалы используются.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'padding-bottom': '16em',
                    'overflow': 'hidden',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: -85,
                    right: -35,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>오일 필터</b>',
                image: 'filters-oil.png',
                imageStyles: {
                    'z-index': '1'
                },
                description: 'AVANTECH 오일 필터는 일본 및 유럽 제조업체의 유명 브랜드 필터를 생산하는 공장에서 제조됩니다. AVANTECH 필터 생산에는 핀란드 회사 Ahlstrom의 재료가 사용됩니다.<br><br>엔진 작동 중에 형성된 필터에 포함된 요소에서 오일을 필터링하도록 설계되었습니다.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding': '5em 0',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 3.6,
                    top: -920,
                    right: -145,
                    scaleX: .8,
                    scaleY: .8
                },
                image: 'filters-oil-inside.png',
                imageStyles: {
                    'left': '60%',
                    'top': '0%',
                    'z-index': '1'
                },
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                tables: [
                    {
                        title: '오일 필터에는 부인할 수 없는 장점이 있습니다.',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 13,
                            right: 53.8,
                            scaleX: .95,
                            scaleY: .95
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 오일 필터의 설계는 견고성, 필터 요소 스크리닝의 완전성, 바이패스 밸브의 개방 압력 측면에서 OEM 표준뿐만 아니라 자동차 제조업체의 원래 사양을 준수합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '배출 방지 밸브는 엔진이 꺼져 있을 때 필터에 오일을 유지하여 냉간 시동 동안 "오일 고갈"을 제거합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '필터 밀봉 링은 저온에 강하여 오일 필터와 엔진 블록의 접합부에서 오일이 흐르는 것을 방지합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '필터 요소의 조임은 오일이 필터 재료를 통해서만 작업 환경에 공급되도록 합니다.',
                                    '필터 요소의 증가된 면적은 엔진 오일의 효과적인 여과를 제공하여 서비스 간격을 늘립니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '12em 0',
                    'z-index': '3',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 12.6,
                    top: -50,
                    right: 17,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>에어 필터</b>',
                image: 'filters-air.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: '에어 필터는 먼지 및 기타 이물질이 엔진에 들어가는 것을 방지하도록 설계되었으며, 이로 인해 메커니즘이 조기에 마모됩니다. 에어 필터의 디자인은 표면뿐만 아니라 전체 볼륨에 걸쳐 먼지 입자를 가두는 다공성 종이로 만든 특수 커튼을 기반으로 합니다. 공기 필터가 막히면 차량 출력이 감소하고, 엔진 탄소 퇴적물이 증가하고, 연료 소비가 증가하고, 이산화탄소 배출 증가로 인한 환경 영향이 증가합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'z-index': '2',
                    'position': 'relative',
                    'padding-top': '5em',
                    'background': '#FFF',
                    'padding-bottom': '12.5em'
                },
                tables: [
                    {
                        title: '에어 필터의 장점:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 4,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 공기 필터는 원래 품질 기준을 충족합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '핀란드 회사 Ahlstrom의 재료가 생산에 사용됩니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 공기 여과지 섬유는 먼지와 이물질을 직경 1미크론까지 걸러냅니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '필터 요소와 필터 하우징의 연결 지점에는 구조의 필요한 견고성을 보장하는 안정적인 씰이 있습니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'z-index': '2',
                    'position': 'relative',
                    'padding': '12em 0 16em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: 105,
                    right: -37,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>캐빈 필터</b>',
                image: 'filters-cabin.png',
                description: '차량 자체의 환기 시스템은 대기에 포함된 먼지, 배기 가스 및 기타 유해 물질로부터 실내로 들어가는 공기를 청소할 수 없습니다. 종종 이것은 건강이 좋지 않고 다양한 질병의 원인입니다. 인간에 대한 위험을 최소화하는 방법은 무엇입니까? 대답은 하나뿐입니다. 고품질 캐빈 필터를 사용하십시오.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding': '12em 0'
                },
                pseudo: {
                    type: 'mountains',
                    index: 0,
                    rotate: 0,
                    top: 20,
                    right: 90,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '숯 필터:',
                image: 'filters-coal.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: '그들은 꽃가루, 박테리아, 이산화탄소, 냄새 및 먼지를 기내로 유입시키지 않습니다. 대도시, 산업단지 등에 설치를 권장합니다. 캐빈필터는 10,000km마다 교체를 권장합니다.'
            },
            {
                type: 'product-type',
                styles: {
                    'margin-bottom': '12.5em',
                    'padding': '12em 0'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 86.4,
                    top: -850,
                    right: -50,
                    scaleX: 0.7,
                    scaleY: -0.7
                },
                title: '표준 필터:',
                image: 'filters-cabin-standard.png',
                description: '아스팔트, 시멘트 먼지 등의 공기를 정화합니다. 교통량이 적은 지역(농촌 지역)에 설치하는 것이 좋습니다.'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding': '13em 0',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 178.2,
                    top: -140,
                    right: -10,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>연료 필터</b>',
                image: 'filters-fuel.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: '연료 필터는 연료 라인을 통해 엔진에 들어가는 연료에 포함된 불순물과 물을 청소하는 데 필요합니다. AVANTECH 연료 필터 제품군에는 휘발유 및 디젤 엔진용 필터가 포함됩니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: '연료 필터의 장점:',
                        pseudo: {
                            type: 'waves', 
                            index: 0,
                            rotate: 0,
                            top: 10,
                            right: 77.5,
                            scaleX: .8,
                            scaleY: .5
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    '각 AVANTECH 연료 필터는 차량의 원래 사양과 일치하도록 설계되었습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '밀봉된 하우징은 연료가 엔진 부품에 닿는 것을 방지합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 연료 필터의 품질은 생산의 모든 단계에서 제어됩니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};