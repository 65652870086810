export const ru = {
    lamps: {
        description: `
            Одно из лучших предложений в среднем ценовом диапазоне по цене, качеству и ассортименту. Покрывают потребности современного автопарка европейского, японского, корейского и американского производства.
            <br><br>
            Производятся исключительно в Южной Корее на современном роботизированном производстве. Каждая лампа AVANTECH проходит контроль геометрии нити накала, тест на утечку газа и тест на устойчивость к вибрации.
        `,
        videos: [],
        content: [
            {
                type: 'product',
                styles: {
                    'margin': '5em 0',
                    'overflow': 'hidden'
                },
                pseudo: {
                    type: 'lines-cut',
                    index: 0,
                    rotate: 0,
                    top: -25,
                    right: -65,
                    scaleX: 1,
                    scaleY: 1
                },
                title: 'МАКСИМАЛЬНО БЕЛЫЙ<br> СТИЛЬНЫЙ СВЕТ<br> <b>NIGHT FIGHTER</b>',
                image: 'lamps-night.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Серия AVANTECH NIGHT FIGHTER – лампы с синим покрытием колбы. Повышенная яркость и цветовая температура 5000К обеспечивают таким лампам неизменную популярность среди современных автовладельцев. Лампы AVANTECH NIGHT FIGHTER придают свету фар интенсивный белый оттенок с эффектом ксенонового источника освещения. Гарантируют максимальную контрастность и чёткую детализацию дорожной обстановки. <br><br>Полное соответствие всем требованиям технических регламентов РФ делает возможным установку ламп в качестве штатного света как на частный, так и на коммерческий транспорт.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ СЕРИИ NIGHT FIGHTER',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 120,
                            right: 76,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Цветовая температура 5000K - холодный белый свет, комфортный для глаз водителя.',
                                    '+110% света от фар на дороге в темное время суток.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '+50% более белый свет (в сравнении со стандартной лампой).',
                                    'Увеличенный световой пучок дает больше времени на реакцию.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Современный, стильный вид фар автомобиля.',
                                    'Сертификаты EAC и ECE.',
                                    'Комплект 2 шт. в упаковке типа блистер.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Кварцевое стекло для увеличенного срока службы.',
                                    'Полный ассортимент для большинства современных автомобилей с цоколями ламп 9005 (HB3) 9006 (HB4) H1 H11 H16 H27/1 H3 H4 H7 H8 H9.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                title: 'КОНТРОЛЬ НАД ДОРОГОЙ В<br> ДОЖДЬ, ТУМАН И СНЕГ<br> <b>FOG FIGHTER</b>',
                image: 'lamps-fog.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Серия AVANTECH FOG FIGHTER – лампы с противотуманным эффектом. Дают тёплый желтый свет с температурой 3000 K. Светят ярче на 50% по сравнению со стандартными лампами в сложных климатических условиях (дождь, туман, снег). Могут быть установлены как основное или дополнительное головное освещение.<br><br>Желтый солнечный оттенок свету ламп AVANTECH FOG FIGHTER обеспечивает специальное интерференционное покрытие, нанесенное на колбы. Цветовая температура 3000К улучшает контрастность на дороге во время движения в неблагоприятных погодных условиях. Дело в том, что чисто белый свет фар во время осадков интенсивно отражается от мелких капель влаги либо снежинок и слепит водителя. Желтый свет в данных условиях более предпочтителен, так как не перегружает глаза водителя и позволяет видеть дальше. Кроме того, в плохих погодных условиях автомобиль с лампами AVANTECH FOG FIGHTER гораздо лучше заметен на дороге, что существенно снижает риск ДТП.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ СЕРИИ FOG FIGHTER:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Цветовая температура 3000K - лампы с желтым светом для сложных погодных условий.',
                                    '+50% света в туман, дождь, снег.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Мягкий, комфортный желтый свет.',
                                    'Кварцевое стекло для увеличенного срока службы.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Сертификаты EAC и ECE',
                                    'Комплект 2 шт. в упаковке типа блистер.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Полный ассортимент для большинства современных автомобилей с цоколями ламп 9005 (HB3) 9006 (HB4) H1 H11 H16 H27/1 H3 H4 H7 H8 H9.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: -115,
                    right: -25,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>ГАЛОГЕННЫЕ ЛАМПЫ</b>',
                image: 'lamps-halogen.png',
                description: 'Галогенные лампы AVANTECH – проверенное годами производства и эксплуатации классическое решение. В них оптимально сочетается качество изготовления, гарантированный срок службы и выгодная цена. Обычно лампы такого типа устанавливаются как штатные во многие автомобили и по европейским нормативам используются как обязательный запасной комплект.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ ГАЛОГЕННЫХ ЛАМП:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: 10,
                            right: 92,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Кварцевое стекло для обеспечения увеличенного срока службы.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Соответствуют ОЕМ-стандартам.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Сертификаты EAC и ECE'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Полный ассортимент для большинства современных автомобилей с цоколями ламп 9005 (HB3).'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding' : '14em 0'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 96.8,
                    top: -300,
                    right: -55,
                    scaleX: 0.7,
                    scaleY: -0.7
                },
                title: '<b>Ксеноновые лампы</b>',
                image: 'lamps-hid.png',
                description: 'Ксеноновые лампы AVANTECH полностью соответствуют заводским параметрам оригинальных ламп и предназначены для замены штатного ксенона.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '1',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ КСЕНОНОВЫХ ЛАМП:',
                        pseudo: {
                            type: 'marks',
                            index: 1,
                            rotate: 178.2,
                            top: 142,
                            right: -12,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Цветовая температура ламп - 4300 К.',
                                    'Световой поток ксеноновой лампы на 80% больше, чем у стандартной галогенной лампы.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Ещё одно ключевое преимущество ксеноновых ламп – это срок службы: они работают до 5 раз дольше, чем галогенные.'
                                ]
                            },
                            {
                                collapse: 2,
                                content: [
                                    'При производстве ламп используются только современные материалы – кварцевое стекло, молибденовая нить от лидирующих европейских производителей и керамические трубки из Японии.'
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -55,
                    right: 110,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>Светодиодные лампы</b>',
                image: 'lamps-led.png',
                description: 'Светодиодные лампы AVANTECH новый современный источник света, появление которого стало возможным благодаря развитию высоких технологий.<br><br>Светодиодные источники света стали очередным этапом развития индустрии автомобильных ламп. По сравнению с уже ставшими привычными галогенными и ксеноновыми лампами имеют ряд неоспоримых преимуществ'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ Светодиодных ЛАМП:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 0,
                            top: 13,
                            right: 91,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Долговечность – до 20 000 часов.',
                                    'Низкое тепловыделение – безопасно для пластиковых фар.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Стиль – яркий белый свет до 6500К.',
                                    'Низкое энергопотребление – до 2,5 раз ниже по сравнению с галогенной лампой.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Эффективность – до 110 люмен с 1 ватта.',
                                    'Безопасность – не содержит ртути, не излучают в УФ-спектре.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Производство 100% Корея. Светодиоды производства LUMIMICRO и LG INNOTEK LED.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};