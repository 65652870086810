export const kr = {
    pumps: {
        description: `
            내연기관이 안정적으로 작동하기 위해서는 연료를 적정량, 적정 압력으로 지속적으로 공급해야 합니다. 이 공급은 엔진의 연료 시스템에 의해 제공됩니다. 연료 시스템의 핵심은 연료 펌프입니다.
            <br>
            따라서 연료 펌프는 차량의 작동을 보장하는 중요한 구성 요소 중 하나입니다.`,
        videos: [],
        content: [
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'padding': '13em 0'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -110,
                    right: -40,
                    scaleX: 1,
                    scaleY: 1 
                },
                image: 'pumps-fuel.png',
                description: '이 제품은 많은 한국 및 일본 차량과 호환됩니다.<br><br>아반텍 연료 펌프는 대한민국에서 제조되며 OEM 사양은 물론 국제 요구 사항을 충족합니다.'
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '10em',
                    'min-height': '80px',
                    'padding-bottom': '45px',
                    '--lg-min-height': '250px'
                },
                pseudo: {
                    type: 'lines-pumps',
                    index: 0,
                    rotate: 0,
                    top: -150,
                    right: 23.5,
                    scaleX: 1,
                    scaleY: 1.5 
                },
                box: [
                    {
                        tag: 'b',
                        content: 'Avantech 연료 펌프에는 뛰어난 기능이 있습니다.'
                    },
                    {
                        tag: 'li',
                        content: '높은 빌드 품질',
                    },
                    {
                        tag: 'li',
                        content: '사양 성능 준수',
                    },
                    {
                        tag: 'li',
                        content: '긴 수명',
                    }
                ]
            },
            {
                type: 'table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                columns: [
                    {
                        header: '공급업체 코드',
                        values: ['AFP0101', 'AFP1001', 'AFP0104', 'AFP0105', 'AFP0106', 'AFP0107', 'AFP0108', 'AFP0301', 'AFP0701', 'AFP1801']
                    },
                    {
                        header: '일의 압박감, kPa',
                        values: ['310', '350', '120', '300', '310', '310', '300', '350', '100', '400']
                    },
                    {
                        header: '최대 압력, kPa',
                        values: ['600', '600', '250', '600', '600', '600', '600', '900', '270', '800']
                    },
                    {
                        header: '생산력, l/h',
                        values: ['90', '80', '110', '120', '90', '80', '100', '90', '55', '140']
                    },
                    {
                        header: '작동 전압, V',
                        values: ['12', '12', '12', '12', '12', '12', '12', '12', '12', '12']
                    },
                ]
            },
        ]
    }
};