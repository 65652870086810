export const kr = {
    belts: {
        description: `구동 벨트는 크랭크축 풀리에서 토크를 전달하여 ICE 부착물과 냉각수 펌프를 구동하도록 설계되었습니다. 특정 차량의 구동 벨트 수는 내연 기관의 설계 기능에 따라 다릅니다.`,
        videos: [],
        content: [
            {
                type: 'list',
                styles: {
                    'margin-top': '5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: -40,
                    top: 75,
                    right: -25,
                    scaleX: -1,
                    scaleY: 1
                },
                list: [
                    {
                        title: '가용성 및 다양한 선택',
                        content: '제품 범위는 러시아 모든 지역의 함대를 고려하여 구성됩니다. Avantech 드라이브 벨트는 승용차 및 경상용차용으로 설계되었습니다. 전체적으로 제품 목록에는 일본, 한국, 유럽, 러시아 생산 차량에 설치하도록 설계된 500 가지 이상의 벨트 유형이 포함됩니다.'
                    },
                    {
                        title: '생산',
                        content: 'AVANTECH 드라이브 벨트의 생산은 인도네시아에 있으며 가장 높은 국제 표준을 충족합니다. 또한, 공장의 제품은 아시아 지역에 위치한 자동차 회사의 조립 라인에 공급됩니다.'
                    },
                    {
                        title: '기술',
                        content: '벨트는 고품질 EPDM 고무로 만들어집니다. 동시에 생산 기술은 러시아 벨트 작동의 기후 특성을 고려합니다.<br><br>Avantech 팀은 생산과 지속적으로 상호 작용하여 제품 특성을 개선하고 필요에 따라 범위를 확장합니다. 러시아 고객의.'
                    }
                ]
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '12.5em',
                    'min-height': '30px',
                    'gap': '2.5em'
                },
                boxStyles: {
                    'left': '0'
                },
                box: [
                    {
                        tag: 'b',
                        content: 'Avantech 벨트의 장점:'
                    },
                    {
                        tag: 'li',
                        content: '높은 내마모성'
                    },
                    {
                        tag: 'li',
                        content: '장기간 사용'
                    },
                    {
                        tag: 'li',
                        content: '작은 소음'
                    },
                    {
                        tag: 'li',
                        content: '작동 중 낮은 신축성'
                    },
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '15em 0'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -310,
                    right: -15,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>V-BELTS</b>',
                image: 'belts-v.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: '일반적으로 V-BELT 는 삼각형의 모서리에 위치한 3개의 메인 풀리(크랭크축, 발전기 및 펌프)를 연결합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '1.25em',
                    'margin-bottom': '11em',
                    'z-index': '2',
                    'position': 'relative',
                    'background': '#FFF'
                },
                tables: [
                    {
                        title: 'V-BELT 의 특징:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH V-BELT 의 최상층은 강도와 유연성을 제공하며 기술적 유체에 대한 내성이 있습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH V-BELT 의 코드는 신축성 소재에 강한 아라미드로 만들어졌습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '압착 고무 층은 AVANTECH V-BELT 의 강도를 보장합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH V-BELT 의 하단 레이어는 충격 하중을 흡수하고 벨트가 갈라지는 것을 방지합니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 3.6,
                    top: -280,
                    right: -155,
                    scaleX: .8,
                    scaleY: .8 
                },
                title: '<b>폴리 V-BELTS<br>(리치) 벨트</b>',
                image: 'belts-semi-v.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: '일반적으로 V-BELTS 는 삼각형의 모서리에 위치한 3개의 메인 풀리(크랭크축, 발전기 및 펌프)를 연결합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: '많은 V-BELTS 의 특징:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 90,
                            top: 27,
                            right: 90,
                            scaleX: .7,
                            scaleY: .7 
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'AVANTECH V 리브 벨트의 최상층은 강도와 유연성을 제공하며 기술적 유체에 대한 내성이 있습니다. 특수 코팅은 마찰로 인해 나타나는 고온의 부정적인 영향으로부터 벨트를 보호합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '성형된 클로로프렌 고무와 EPDM을 사용하여 긴 벨트 수명, 치수 안정성 및 최대 쐐기 압력을 보장합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '나일론 코드는 AVANTECH V 리브 벨트에 탁월한 토크 전달 기능을 제공합니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};