import { computed } from "vue";

const robotDetection = computed(() => {
    const robots = new RegExp(
        [
            /bot/,
            /spider/,
            /crawl/,
            /APIs-Google/,
            /AdsBot/,
            /Googlebot/,
            /Googlebot-Mobile/,
            /mediapartners/,
            /Google Favicon/,
            /Googlebot-Image/,
            /FeedFetcher/,
            /Google-Read-Aloud/,
            /DuplexWeb-Google/,
            /googleweblight/,
            /bing/,
            /yandex/,
            /baidu/,
            /duckduck/,
            /yahoo/,
            /ecosia/,
            /facebook/,
            /instagram/,
            /pinterest/,
            /reddit/,
            /slack/,
            /twitter/,
            /whatsapp/,
            /youtube/,
        ]
            .map((r) => r.source)
            .join("|"),
        "i"
    );
    return robots.test(navigator.userAgent);
})

export { robotDetection }