export const en = {
    steering: {
        description: `Avantech Parts Group manufactures suspension and steering parts at spare parts manufacturing plants in Malaysia, Turkey and Taiwan. On the conveyors of these plants, both original and analog parts of world brands are produced.`,
        videos: [],
        styles: {
            'margin-bottom': '7.5em'
        },
        content: [
            {
                type: 'grid-table',
                styles: {
                    'margin-top': '5em'
                },
                tables: [
                    {
                        title: 'THE VEHICLE SUSPENSION PERFORMS <br>THE FOLLOWING FUNCTIONS:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 51.5,
                            scaleX: .5,
                            scaleY: .5
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Connects wheels or solid axles to the vehicle\'s carrier system - body or frame.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Transfers to the carrier system the forces and moments arising from the interaction of the wheels with the road.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Provides the required nature of the movement of the wheels relative to the body or frame, as well as the necessary smoothness.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: []
                            }
                        ]
                    }
                ]
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '8em',
                    'min-height': '60px',
                    'z-index': '1',
                    '--lg-min-height': '300px'
                },
                boxStyles: {
                    'background': '#fff'
                },
                box: [
                    {
                        tag: 'b',
                        content: 'The range of AVANTECH includes:'
                    },
                    {
                        tag: 'p',
                        content: `
                            steering tips, tie rods,<br>
                            ball joints,<br>
                            stabilizer links, etc.
                        `
                    },
                    {
                        tag: 'b',
                        content: 'Applicability:'
                    },
                    {
                        tag: 'p',
                        content: 'Japanese, Korean, European cars.'
                    }
                ]
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '12.5em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 93.2,
                    top: -220,
                    right: -177,
                    scaleX: -.9,
                    scaleY: .9
                },
                templates: [
                    {
                        title: 'OEM standard',
                        description: 'The safety of the car on the road, the comfort of movement and ease of control depend on the condition of the suspension. It is no secret that until recently only original products could guarantee a stable level of quality and safety. With the advent of AVANTECH products, car owners have the opportunity not to overpay for suspension and steering parts that meet high OEM requirements and standards. The price level for AVANTECH products is up to 50% lower compared to products of well-known Korean and Japanese manufacturers in these categories.'
                    },
                    {
                        title: 'Quality control',
                        description: 'AVANTECH suspension and steering parts are manufactured by Avantech Parts Group in state-of-the-art factories specialized in chassis and steering parts. High demand is ensured by a combination of modern technologies and lower production costs in comparison with Japanese concerns. The production process complies with the requirements of the international quality control system ISO / TS 16949. The products have passed a series of tests confirming their use in the difficult climatic conditions of Russia in accordance with the requirements of GOST.'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'SUSPENSION AND STEERING PARTS ADVANTAGES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 90,
                            top: 44,
                            right: 91,
                            scaleX: 1.4,
                            scaleY: 1.4
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Designed to OEM quality standards for superior strength, reliability and durability.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Manufactured from durable steel, designed for use in difficult road conditions.',
                                    'High quality metal processing prevents corrosion.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The rubber parts of the parts are resistant to low temperatures down to -50°C.',
                                    'The parts are equipped with the necessary fasteners (nuts, cotter pins), which eliminates the need for previously used fasteners.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Protective covers on anthers guarantee their safety from mechanical damage during transportation and storage.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: 100,
                    right: -31,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>STEERING TIPS</b>',
                image: 'steering-rod.png',
                description: 'These are parts of the steering mechanism that provide mobility and the desired angle of rotation of the wheels.<br><br>Comfortable control and traffic safety are ensured by all parts of the steering mechanism, but most often it is the tips that fail. The design of the steering tips is based on spherical joints. Steering tips are subject to heavy loads and factors such as the condition of the road surface, speed of movement, driving over bumps. The steering tip is the most loaded part of the car\'s suspension. Untimely replacement of tips can lead to malfunction of the steering system and costly repairs.'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '1',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -95.8,
                    top: 195,
                    right: -55,
                    scaleX: .8,
                    scaleY: .8
                },
                title: '<b>TIE RODS</b>',
                image: 'steering-rack.png',
                description: 'Elements of the steering system for transmitting force from the steering mechanism to the wheels at a given angle. The steering rod consists of a long metal rod and a ball mechanism. Since the tie rod transmits momentum from the steering gear to the wheels, it experiences constant loads, and the ball mechanism wears out. Damage to the tie rod can be noticed by knocking, increased tire wear and uneven wheel movements.'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 1,
                    rotate: 35.5,
                    top: -155,
                    right: -40,
                    scaleX: -1,
                    scaleY: 1
                },
                title: '<b>BALL JOINTS</b>',
                image: 'steering-joint.png',
                description: 'They are the attachment of the wheel to the suspension of the car. Allows the wheel to move in a horizontal plane and prevents it from moving in a vertical one. In other words, it does not allow the wheel to fall sideways. The quality of the ball joint is extremely important. This element is of great importance for the handling of the car. If the support breaks, the wheel may even detach from the car. In any case, if the ball joint fails, further movement of the car on its own is impossible. That is why AVANTECH carefully approaches the development and testing of its ball joints, achieving maximum quality and adaptation to operation in low temperatures and off-road conditions.'
            },
            {
                type: 'product',
                styles: {
                    'z-index': '1',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'mountains',
                    index: 0,
                    rotate: 90,
                    top: 15,
                    right: 95,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>STABILIZER\'S POLE</b>',
                image: 'steering-link.png',
                description: 'The stabilizer is designed to ensure that the car is stable on the road, does not roll and keeps the trajectory. The stabilizer links connect the stabilizer and the central suspension element. It is difficult to overestimate the importance of serviceable stabilizer struts for driving safety. With broken stabilizer links, the car loses its controllability and becomes less predictable.<br><br>AVANTECH stabilizer links are made in factories that produce high-quality products for most popular cars. All products are thoroughly tested, both in laboratory and in real conditions. Therefore, we are confident in the quality of our products.'
            },
        ]
    }
};