export const kr = {
    rubber: {
        description: `자동차에서 가장 많은 하중을 받는 부품은 섀시 부품으로, 무엇보다 고무 제품(RTI)이 고르지 않은 포장 도로에서 주행할 때 발생하는 충격 하중의 가장 큰 부분을 담당합니다. 이 범주에는 자동차의 엔진 및 변속기와 같은 구성 요소의 올바른 작동을 담당하는 기타 고무 제품뿐만 아니라 무음 레버 블록, 안 티롤 바 부싱, 충격 흡수 장치 마운트가 포함됩니다. 여기에는 엔진 지지대(베개)와 자동차 변속기가 포함됩니다.`,
        videos: [],
        content: [
            {
                type: 'description-box',
                split: true,
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '3',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: 100,
                    right: 95,
                    scaleX: 1,
                    scaleY: 1 
                },
                templates: [
                    {
                        title: '품질 기준 준수',
                        description: 'AVANTECH 사일런트 블록 및 부싱은 낮은 온도의 혹독한 러시아 기후에서 작동할 때 잘 입증되었습니다. 러시아 연구소에서 AVANTECH 제품은 찢어짐, 비틀림, 변위에 대한 모든 테스트를 성공적으로 통과하여 국제 품질 표준 준수를 보장할 수 있습니다.'
                    },
                    {
                        title: '생산',
                        description: 'Avantech Parts Group은 태국과 말레이시아에 위치한 예비 부품 제조 공장에서 서스펜션 및 스티어링 부품의 AVANTECH 브랜드로 고무 제품을 제조합니다. 이 공장의 컨베이어에서는 세계 브랜드의 오리지널 부품과 아날로그 부품이 모두 생산됩니다.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 84.1,
                    top: -55,
                    right: -182,
                    scaleX: .9,
                    scaleY: .9 
                },
                title: '<b>사일런트 블록</b>',
                image: 'rubber-suspension.png',
                imageStyles: {
                    'right': '5%',
                },
                description: '서스펜션에 충격 하중을 가하는 데 필요했습니다. 사일런트 블록은 두 개의 금속 부싱 사이에 고무 인서트가 있는 단단한 회전 조인트입니다. 자동차 타이어의 마모율, 자동차의 제동 품질, 스티어링과의 연결을 결정하는 것은 사일런트 블록의 상태입니다. 무음 블록에 결함이 있으면 모든 서스펜션 부품의 마모가 가속화되어 향후 자동차 수리 비용이 많이 듭니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'SILENTBLOCK의 장점:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 90,
                            top: 22,
                            right: 98,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'AVANTECH 사일런트 블록은 내마모성이 향상된 고품질 고무 컴파운드로 개발되어 가장 극한의 주행 조건에서 차량의 핸들링을 장기적으로 탁월하게 제어할 수 있습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 사일런트 블록에 사용되는 고무 컴파운드는 최대 -40⁰С의 온도에서 탄성, 탄력성 및 강도를 유지하도록 보장됩니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '자동차 제조업체의 원래 품질 기준을 준수합니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '11em 0 9em'
                },
                title: '<b>부싱</b>',
                image: 'rubber-hub.png',
                imageStyles: {
                    'z-index': '4'
                },
                description: '앤티롤 바는 코너에서 자동차의 횡방향 롤링을 줄이는 데 사용됩니다. 그의 작업은 부하의 재분배를 기반으로 합니다. 고속 주행시 필요하며 필수 불가결합니다. 스태빌라이저는 차량이 속도를 잃지 않고 가능한 한 빨리 회전할 수 있도록 도와 전복을 방지합니다.<br><br>안티롤 바의 효과적인 작동은 탄성 요소에 의해 보장되며, 그 중 하나는 안티 롤 바 부싱. 스태빌라이저 부품의 결합 작업 덕분에 급커브를 통과할 때 편안하고 안전합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding': '5em 0 7.5em',
                    'background': '#FFF',
                    'z-index': '3',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: '스태빌라이저 부싱 기능:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 10,
                            right: 83,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH 스태빌라이저 부싱은 내마모성이 향상된 고품질 고무 컴파운드로 만들어졌습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '가혹한 러시아 조건에서 작동하도록 설계되었습니다.',
                                    '진동 수준을 줄입니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '그들은 높은 강도와 기계적 응력에 대한 저항성을 가지고 있습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '자동차 제조업체의 원래 품질 기준을 준수합니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};