import selectionStaticJson from "@assets/static/json/selection.json";

/**
 * Vuex module initiating selection component
 *
 * @return state, mutations, getters
 */

const state = () => {
    return {
        items: selectionStaticJson,
    };
};

const getters = {
    getTabs: (state) => {
        return state.items;
    },
    getTabInputs: (state) => {
        for (const tab of state.items) {
            if (tab.active) return tab.inputs;
        }
        return [];
    },
    getRequiredInputs: (state) => (tabId, required, id) => {
        const tab = getTabByID(state, tabId);
        const inputs = [];
        const exist = [];

        for (const requires of required) {
            for (const item of replace(requires)) {
                const input = tab.inputs.find((input) => input.id == item);
                if (input && input.value != null && !exist.includes(item)) {
                    exist.push(item);
                    inputs.push(input);
                }
            }
        }

        return inputs;
    },
    isInputDisabled: (state) => (tabId, requires) => {
        const tab = getTabByID(state, tabId);
        if (tab !== null && requires.length > 0) {
            return !isRequiredInputs(requires, tab.inputs);
        }
        return false;
    },
    isButtonDisabled: (state) => (tabId, requires) => {
        const tab = getTabByID(state, tabId);
        if (tab !== null && requires.length > 0) {
            return !isRequiredInputs(requires, tab.inputs);
        }
        return state.isButtonDisabled;
    },
};

const mutations = {
    setActiveTab(state, payload) {
        const prop = payload.tabProp ? payload.tabProp : "id";
        state.items.forEach((tab) => {
            if (tab[prop] == payload.tabId) {
                tab.active = true;
            } else {
                tab.active = false;
                // Закоменченно т.к. из-за строки не работает восстановление выбранных параметров при переключении владок
                // tab.inputs.forEach((input) => (input.value = null));
            }
        });
    },
    setInputProperty(state, payload) {
        state.items.forEach((tab) => {
            if (tab.id == payload.tabId) {
                tab.inputs.forEach((input) => {
                    if (input.id == payload.inputId) {
                        input[payload.property] = payload.value;
                    }
                });
            }
        });
    },
    setDefaultValues(state, payload) {
        state.items.forEach((tab) => {
            if (tab.api == payload.api) {
                tab.inputs.forEach((input) => {
                    if (payload[input.id] != undefined) {
                        input.value = payload[input.id];
                    }
                });
            }
        });
    },
};

const actions = {};

const isRequiredInputs = (required, inputs) => {
    let status = false;

    for (const require of required) {
        for (const item of replace(require)) {
            status = false;
            const input = inputs.find((input) => input.id == item);
            if (input && input.value != null) {
                status = true;
                break;
            }
        }
        if (status == false) {
            return status;
        }
    }

    return status;
};

const getTabByID = (state, id) => {
    for (const tab of state.items) if (tab.id == id) return tab;
    return null;
};

const replace = (require) => {
    return require.replace(/ /g, "").split(",");
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
