import { createStore } from 'vuex';
import SelectionStore from './selection.js';
import StaticStorage from './static';
import SimpleModalStore from 'vue3-simple-modal-form/dist/storage/index.js';

const state = () => {
    return {
        product: null,
        isMenuActive: false
    }
}

const mutations = {
    SET_PRODUCT(state, payload) {
        state.product = payload;
    },
    OPEN_MENU(state, payload) {
        state.isMenuActive = payload;
    }
}

const getters = {
    getProduct: (state) => state.product
}

const actions = {}

const store = createStore({
    modules: {
        default: {
            namespaced: true,
            state,
            getters,
            mutations,
            actions
        },
        modal: SimpleModalStore,
        selection: SelectionStore,
        static: StaticStorage,
    }
});

export { store as createStore } ;
