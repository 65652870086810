import { messages as wipers } from './wipers'
import { messages as lamps } from './lamps'
import { messages as filters } from './filters'
import { messages as brakes } from './brakes'
import { messages as anthers } from './anthers'
import { messages as rubber } from './rubber'
import { messages as belts } from './belts'
import { messages as pumps } from './pumps'
import { messages as steering } from './steering'

let messages = { 
    'ru': {}, 
    'en': {}, 
    'kr': {}
};

const catalog = [
    wipers,
    lamps,
    filters,
    brakes,
    anthers,
    rubber,
    belts,
    pumps,
    steering
];

catalog.forEach((component) => {
    for (const item in messages) {
        messages[item] = Object.assign(messages[item], component[item] ?? {});
    }
});

export { messages }