export const kr = {
    steering: {
        description: `Avantech Parts Group은 말레이시아, 터키 및 대만의 예비 부품 제조 공장에서 서스펜션 및 스티어링 부품을 제조합니다. 이 공장의 컨베이어에서는 세계 브랜드의 오리지널 부품과 아날로그 부품이 모두 생산됩니다.`,
        videos: [],
        styles: {
            'margin-bottom': '7.5em'
        },
        content: [
            {
                type: 'grid-table',
                styles: {
                    'margin-top': '5em'
                },
                tables: [
                    {
                        title: '차량 서스펜션은 다음과 같은 기능을 수행합니다.',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 51.5,
                            scaleX: .5,
                            scaleY: .5
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '차체 또는 프레임과 같은 차량의 캐리어 시스템에 휠 또는 솔리드 액슬을 연결합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '바퀴와 도로의 상호 작용에서 발생하는 힘과 모멘트를 캐리어 시스템으로 전달합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '필요한 부드러움뿐만 아니라 차체 또는 프레임에 대한 바퀴의 움직임에 필요한 특성을 제공합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: []
                            }
                        ]
                    }
                ]
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '8em',
                    'min-height': '90px',
                    'z-index': '1',
                    '--lg-min-height': '300px'
                },
                boxStyles: {
                    'background': '#fff'
                },
                box: [
                    {
                        tag: 'b',
                        content: 'AVANTECH의 범위에는 다음이 포함됩니다.'
                    },
                    {
                        tag: 'p',
                        content: `
                            스티어링 팁, 타이로드,<br>
                            볼 조인트,<br>
                            스태빌라이저 링크 등
                        `
                    },
                    {
                        tag: 'b',
                        content: '적용 가능성:'
                    },
                    {
                        tag: 'p',
                        content: '일본, 한국, 유럽 자동차.'
                    }
                ]
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '12.5em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 93.2,
                    top: -270,
                    right: -180,
                    scaleX: -.9,
                    scaleY: .9
                },
                templates: [
                    {
                        title: 'OEM 표준',
                        description: '도로에서 차량의 안전성, 움직임의 편안함 및 제어 용이성은 서스펜션 상태에 따라 다릅니다. 최근까지 정품만이 안정적인 품질과 안전성을 보장할 수 있었다는 것은 비밀이 아닙니다. AVANTECH 제품의 출현으로 자동차 소유자는 높은 OEM 요구 사항 및 표준을 충족하는 서스펜션 및 스티어링 부품에 대해 초과 지불하지 않을 수 있습니다. AVANTECH 제품의 가격 수준은 이러한 범주에서 잘 알려진 한국 및 일본 제조업체의 제품에 비해 최대 50% 저렴합니다.'
                    },
                    {
                        title: '품질 관리',
                        description: 'AVANTECH 서스펜션 및 스티어링 부품은 섀시 및 스티어링 부품을 전문으로 하는 최첨단 공장에서 Avantech Parts Group에 의해 제조됩니다. 현대 기술과 일본의 우려에 비해 낮은 생산 비용의 조합으로 높은 수요가 보장됩니다. 생산 공정은 국제 품질 관리 시스템 ISO / TS 16949의 요구 사항을 준수합니다. 제품은 GOST의 요구 사항에 따라 러시아의 어려운 기후 조건에서의 사용을 확인하는 일련의 테스트를 통과했습니다.'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: '서스펜션 및 스티어링 부품의 장점:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 90,
                            top: 44,
                            right: 91,
                            scaleX: 1.4,
                            scaleY: 1.4
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '우수한 강도, 신뢰성 및 내구성을 위해 OEM 품질 표준에 따라 설계되었습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '어려운 도로 조건에서 사용하도록 설계된 내구성 있는 강철로 제조되었습니다.',
                                    '고품질 금속 가공으로 부식을 방지합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '부품의 고무 부분은 -50°C까지의 저온에 강합니다.',
                                    '부품에는 필요한 패스너(너트, 코터 핀)가 장착되어 있어 이전에 사용한 패스너가 필요하지 않습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '꽃밥의 보호 덮개는 운송 및 보관 중 기계적 손상으로부터 안전을 보장합니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: 100,
                    right: -31,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>스티어링 팁</b>',
                image: 'steering-rod.png',
                description: '이들은 바퀴의 이동성과 원하는 회전 각도를 제공하는 조향 메커니즘의 일부입니다.<br><br>조향 메커니즘의 모든 부분이 편안한 제어와 교통 안전을 보장하지만 대부분 실패하는 것은 팁입니다. . 스티어링 팁의 디자인은 구형 조인트를 기반으로 합니다. 스티어링 팁은 노면 상태, 이동 속도, 범프 위를 주행하는 것과 같은 요인과 무거운 하중에 영향을 받습니다. 스티어링 팁은 자동차 서스펜션에서 가장 많은 하중을 받는 부분입니다. 시기 적절하지 않은 팁 교체는 스티어링 시스템의 오작동과 비용이 많이 드는 수리로 이어질 수 있습니다.'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '1',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -95.8,
                    top: 195,
                    right: -55,
                    scaleX: .8,
                    scaleY: .8
                },
                title: '<b>타이로드</b>',
                image: 'steering-rack.png',
                description: '주어진 각도에서 스티어링 메커니즘에서 휠로 힘을 전달하기 위한 스티어링 시스템의 요소. 스티어링 로드는 긴 금속 로드와 볼 메커니즘으로 구성됩니다. 타이로드는 스티어링 기어에서 휠로 운동량을 전달하기 때문에 일정한 하중을 받고 볼 메커니즘이 마모됩니다. 타이 로드의 손상은 노크, 타이어 마모 증가 및 고르지 않은 휠 움직임으로 알 수 있습니다.'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '12.5em',
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 1,
                    rotate: 35.5,
                    top: -155,
                    right: -40,
                    scaleX: -1,
                    scaleY: 1
                },
                title: '<b>볼 조인트</b>',
                image: 'steering-joint.png',
                description: '그것들은 자동차 서스펜션에 바퀴를 부착하는 것입니다. 휠이 수평면에서 움직일 수 있게 하고 수직면에서는 움직이지 않도록 합니다. 즉, 바퀴가 옆으로 떨어지는 것을 허용하지 않습니다. 볼 조인트의 품질은 매우 중요합니다. 이 요소는 자동차 핸들링에 매우 중요합니다. 지지대가 파손되면 휠이 차량에서 분리될 수도 있습니다. 어쨌든 볼 조인트가 고장 나면 자동차 자체의 추가 이동이 불가능합니다. 그렇기 때문에 AVANTECH는 볼 조인트의 개발 및 테스트에 신중하게 접근하여 저온 및 오프로드 조건에서 작동에 대한 최대 품질 및 적응을 달성합니다.'
            },
            {
                type: 'product',
                styles: {
                    'z-index': '1',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'mountains',
                    index: 0,
                    rotate: 90,
                    top: 15,
                    right: 95,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>안정제 극</b>',
                image: 'steering-link.png',
                description: '스태빌라이저는 차량이 도로에서 안정적이고 구르지 않으며 궤적을 유지하도록 설계되었습니다. 스태빌라이저 링크는 스태빌라이저와 중앙 서스펜션 요소를 연결합니다. 운전 안전을 위해 사용 가능한 스태빌라이저 스트럿의 중요성을 과대평가하기는 어렵습니다. 스태빌라이저 링크가 끊어지면 차량의 제어 가능성이 떨어지고 예측이 어려워집니다.<br><br>AVANTECH 스태빌라이저 링크는 가장 인기 있는 자동차용 고품질 제품을 생산하는 공장에서 만들어집니다. 모든 제품은 실험실과 실제 조건에서 철저하게 테스트됩니다. 따라서 우리는 제품의 품질에 자신이 있습니다.'
            },
        ]
    }
};