export const ru = {
    pumps: {
        description: `Для стабильной работы двигателя внутреннего сгорания требуется непрерывная подача топлива в нужном количестве и с нужным давлением. Обеспечивает эту подачу топливная система двигателя.  А сердцем топливной системы является топливный насос.
            <br>
            Таким образом топливный насос является одним из критических узлов автомобиля, обеспечивающим его работу.`,
        videos: [],
        content: [
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'padding': '13em 0'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -110,
                    right: -40,
                    scaleX: 1,
                    scaleY: 1 
                },
                image: 'pumps-fuel.png',
                description: 'Продукция совместима с большим количеством автомобилей корейского, японского производства.<br><br>Производство топливных насосов Avantech находится в Республике Корея, соответствует международным требованиям, а также спецификациям OEM производителей.'
            },
            {
                type: 'advantages-box',
                styles: {
                    'margin-bottom': '7em',
                    'min-height': '150px',
                    '--lg-min-height': '250px'
                },
                pseudo: {
                    type: 'lines-pumps',
                    index: 0,
                    rotate: 0,
                    top: -150,
                    right: 23.5,
                    scaleX: 1,
                    scaleY: 1.5 
                },
                box: [
                    {
                        tag: 'b',
                        content: 'Топливные насосы Avantech имеют превосходные характеристики:'
                    },
                    {
                        tag: 'li',
                        content: 'Высокое качество сборки',
                    },
                    {
                        tag: 'li',
                        content: 'Соответствующая спецификациям производительность',
                    },
                    {
                        tag: 'li',
                        content: 'Длительный срок службы',
                    }
                ]
            },
            {
                type: 'table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                columns: [
                    {
                        header: 'Артикул',
                        values: ['AFP0101', 'AFP1001', 'AFP0104', 'AFP0105', 'AFP0106', 'AFP0107', 'AFP0108', 'AFP0301', 'AFP0701', 'AFP1801']
                    },
                    {
                        header: 'Рабочее<br>давление, кПа',
                        values: ['310', '350', '120', '300', '310', '310', '300', '350', '100', '400']
                    },
                    {
                        header: 'Максимальное давление, кПа',
                        values: ['600', '600', '250', '600', '600', '600', '600', '900', '270', '800']
                    },
                    {
                        header: 'Производительность, л/ч',
                        values: ['90', '80', '110', '120', '90', '80', '100', '90', '55', '140']
                    },
                    {
                        header: 'Рабочее напряжение, В',
                        values: ['12', '12', '12', '12', '12', '12', '12', '12', '12', '12']
                    },
                ]
            },
        ]
    }
};