export const kr = {
    anthers: {
        description: `
        AVANTECH 더스트 부츠는 대만과 말레이시아의 정품 부품과 아날로그 부품을 모두 생산하는 예비 부품 제조 공장에서 제조됩니다. 생산은 국제 품질 표준 ISO/TS 16949를 준수합니다.
        `,
        videos: [],
        styles: {
            'margin-top': '5em',
        },
        content: [
            {
                type: 'product',
                styles: {
                    'padding-bottom': '14em'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 88.2,
                    top: -58,
                    right: -23,
                    scaleX: 1,
                    scaleY: 1
                },
                image: 'anthers-boot.png',
                imageStyles: {
                    'top': '60%'
                },
                description: '더스트 부트는 자동차의 각종 부품을 먼지, 오물, 습기, 모래 및 기타 이물질로부터 보호하기 위해 필요한 고무 부트입니다. 또한 내부 윤활유를 오염으로부터 보호하고 서스펜션 수명을 늘립니다.<br><br>AVANTECH의 더스트 부츠 제품군에는 170개 이상의 품목이 포함되어 있으며 일본 자동차용으로 설계되었습니다.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding-bottom': '14em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -86.4,
                    top: -800,
                    right: -61,
                    scaleX: .9,
                    scaleY: .9
                },
                image: 'anthers-boot-rack.png',
                imageStyles: {
                    'top': '20%'
                },
                description: '자동차 서스펜션에서 CV 조인트(등속 조인트), 스티어링 로드, 쇼크 업소버, 스티어링 랙 등을 보호하기 위해 꽃밥이 필요합니다. 자동차 한 대에 총 40~60개가 있을 수 있지만 가장 중요한 것은 10~20개입니다. 이러한 부품을 부적절하게 작동하면 고장이 나고 값비싼 자동차 수리 비용이 발생할 수 있습니다.'
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'padding-bottom': '7.5em'
                },
                templates: [
                    {
                        title: '고강도 및 탄성',
                        description: 'AVANTECH 꽃밥은 시약(모래-소금 혼합물 및 오일 제품)의 공격적인 영향을 고려하여 제작되어 서비스 수명과 기계적 손상에 대한 내성을 증가시킵니다.'
                    },
                    {
                        title: ' ',
                        description: 'AVANTECH 꽃밥 생산에는 고밀도 네오프렌이 사용되어 -50°C ~ +90°C의 온도에서도 강도와 탄성의 손실 없이 특성을 유지할 수 있어 북부 지역에서도 사용할 수 있습니다. 지역.'
                    }
                ]
            },
        ]
    }
};