
function globScrollAnimation() {
    let scrollItems = document.querySelectorAll('[data-scroll-fadeup]:not(.is-inview)');

    if (scrollItems.length > 0) {
        scrollItems.forEach(element => {
            let posTop = element.getBoundingClientRect().top
            element.classList.toggle('is-inview', posTop < window.innerHeight);
        });
    }
};

function elementScrollAnimation(inject) {
    let scrollItems = document.querySelectorAll(inject);
    let selector = [];
    
    if (scrollItems.length > 0) {
        scrollItems.forEach(element => {
            let posTop = element.getBoundingClientRect().top
            let posBottom = element.getBoundingClientRect().bottom
            if (posTop < window.innerHeight && posBottom >= 0)
                selector.push(element.dataset.id);
        });
    }
    return selector;
};

function movementScrollAnimation(elementId) {
    if (document.getElementById(elementId)) {
        const elemOffsetY = document.getElementById(elementId).parentElement.offsetTop;
        const difference = window.scrollY + window.innerHeight - elemOffsetY;
        const elemFull = window.innerHeight * 0.8;
 
        if (difference >= 0) {
            const percetage = (difference / elemFull) * 100;
            return Math.max(100 - percetage, 0);
        }
    }
    return 100;
};

const scrollToElement = (elementId, shift = 85) => {
    const elementHtml = document.getElementById(elementId);
    if (elementHtml !== undefined) {
        window.scrollTo(0, elementHtml.offsetTop - shift);
    }
};

export function useAnimation() { 
    return { globScrollAnimation, movementScrollAnimation, elementScrollAnimation, scrollToElement }
}