export const en = {
    breadcrumbs: {
        home: { name: "Home", to: "/" },
        whereToBuy: { name: 'Where to buy', to: '/store' },
        catalog: { name: "Products", to: "/catalog" },
        about: { name: "About the company", to: "/about" },
        assurance: { name: "Warranty", to: "/assurance" },
        inform: { name: "Imformation", to: "/inform" },
        partners: { name: "To partners", to: "/partners" },
        anthers: { name: "Anthers", to: "/catalog/anthers" },
        belts: { name: "Drive belts", to: "/catalog/belts" },
        discs: { name: "Brake discs and drums", to: "/catalog/discs" },
        filters: { name: "Filters", to: "/catalog/filters" },
        lamps: { name: "Lamps", to: "/catalog/lamps" },
        brakes: { name: "Brake system", to: "/catalog/brakes" },
        pumps: { name: "Fuel pumps", to: "/catalog/pumps" },
        rubber: { name: "Rubber products", to: "/catalog/rubber" },
        springs: { name: "Suspension springs", to: "/catalog/springs" },
        steering: { name: "Suspension and steering parts", to: "/catalog/steering" },
        wipers: { name: "Wiper blades", to: "/catalog/wipers" },
    },
};
