export const en = {
    filters: {
        description: `
            Clean oil and air for the engine, absence of dust and unpleasant odors in the cabin - these are the main tasks that AVANTECH filters successfully perform. Hundreds of thousands of motorists have already appreciated their high quality, the widest range and attractive prices. AVANTECH offers a wide range of filters for Japanese, Korean, European and American vehicles.
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em'
            //     },
            //     videos: [
            //         {
            //             link: 'TukrCtbk9AQ',
            //             title: 'Масляные фильтры. <br>Краткий обзор.',
            //             description: 'Показываем масляные фильтры Avantech в разборе. Рассказываем, где производятся и какие материалы используются.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'padding-bottom': '16em',
                    'overflow': 'hidden',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: -85,
                    right: -35,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>OIL FILTERS</b>',
                image: 'filters-oil.png',
                imageStyles: {
                    'z-index': '1'
                },
                description: 'AVANTECH oil filters are manufactured at a factory that produces filters for well-known brands from leading Japanese and European manufacturers. In the production of AVANTECH filters, materials from the Finnish company Ahlstrom are used.<br><br>Designed to filter oil from the elements contained in it, formed during engine operation.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding': '5em 0',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 3.6,
                    top: -900,
                    right: -145,
                    scaleX: .8,
                    scaleY: .8
                },
                image: 'filters-oil-inside.png',
                imageStyles: {
                    'left': '55%',
                    'z-index': '1'
                },
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'transform': 'unset',
                    'will-change': 'unset'
                },
                tables: [
                    {
                        title: 'Oil filters have undeniable advantages:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 13,
                            right: 53.8,
                            scaleX: .95,
                            scaleY: .95
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'The design of the AVANTECH oil filter complies with the original specifications of car manufacturers, as well as OEM standards in terms of tightness, completeness of the filter element screening, opening pressure of the bypass valve.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The anti-drain valve keeps the oil in the filter when the engine is off, eliminating "oil starvation" during cold starts.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The filter sealing ring is resistant to low temperatures, which prevents oil from flowing at the junctions of the oil filter with the engine block.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The tightness of the filter element ensures that oil is supplied to the working environment only through the filter material.',
                                    'The increased area of the filter element provides effective filtration of engine oil, which increases service intervals.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '12em 0',
                    'z-index': '3',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 12.6,
                    top: -20,
                    right: 17,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>AIR FILTERS</b>',
                image: 'filters-air.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'The air filter is designed to prevent dust and other foreign particles from entering the engine, the presence of which causes premature wear of the mechanism. The design of the air filter is based on a special curtain made of porous paper, which traps dust particles not only on the surface, but throughout the entire volume. A clogged air filter reduces vehicle power, increases engine carbon deposits, increases fuel consumption, and increases environmental impact due to increased carbon dioxide emissions.'
            },
            {
                type: 'grid-table',
                styles: {
                    'z-index': '2',
                    'position': 'relative',
                    'padding-top': '5em',
                    'background': '#FFF',
                    'padding-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'Advantages of air filters:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 4,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH air filters meet original quality standards.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Materials of the Finnish company Ahlstrom are used in production.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH air filter paper fibers trap dust and foreign particles up to 1 micron in diameter.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The connection points of the filter element and the filter housing have reliable seals, which ensures the necessary tightness of the structure.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'z-index': '2',
                    'position': 'relative',
                    'padding': '12em 0 16em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: 105,
                    right: -37,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>Cabin FILTERS</b>',
                image: 'filters-cabin.png',
                description: 'The ventilation system of the car itself is not capable of cleaning the air entering the passenger compartment from dust, exhaust gases and other harmful substances contained in the atmosphere. Often this is the cause of poor health and various diseases. How to minimize the danger to humans? There is only one answer - use high-quality cabin filters.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding': '12em 0'
                },
                pseudo: {
                    type: 'mountains',
                    index: 0,
                    rotate: 0,
                    top: 20,
                    right: 90,
                    scaleX: 1,
                    scaleY: 1
                },
                title: 'CHARCOAL FILTERS:',
                image: 'filters-coal.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'They do not let pollen, bacteria, carbon dioxide, odors and dust into the cabin. It is recommended to install in large cities, industrial areas, etc. It is recommended to change the cabin filter every 10,000 km.'
            },
            {
                type: 'product-type',
                styles: {
                    'margin-bottom': '12.5em',
                    'padding': '12em 0'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 86.4,
                    top: -700,
                    right: -50,
                    scaleX: 0.7,
                    scaleY: -0.7
                },
                title: 'STANDARD FILTERS:',
                image: 'filters-cabin-standard.png',
                description: 'They purify the air from asphalt, cement dust, etc. It is recommended to install in areas with low traffic flow (rural areas).'
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding': '13em 0',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 178.2,
                    top: -140,
                    right: -10,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>FUEL FILTERS</b>',
                image: 'filters-fuel.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'The fuel filter is necessary to clean the fuel entering the engine through the fuel line from impurities and water contained in it. The range of AVANTECH fuel filters includes filters for both petrol and diesel engines.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Advantages of fuel filters:',
                        pseudo: {
                            type: 'waves', 
                            index: 0,
                            rotate: 0,
                            top: 5,
                            right: 77.5,
                            scaleX: .8,
                            scaleY: .5
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'Each AVANTECH fuel filter is designed to match the vehicle\'s original specification.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The sealed housing prevents fuel from getting on engine parts.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The quality of the AVANTECH fuel filter is controlled at all stages of production.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};