export const ru = {
    brakes: {
        description: `
            Тормозные колодки AVANTECH изготавливаются на современных заводах-производителях в Южной Корее, которые являются ОЕМ-поставщиками на конвейеры крупнейших производителей автомобилей, в том числе HYUNDAI, KIA MOTORS, RENAULT SAMSUNG, FUSO.
            <br><br>
            Тормозные диски AVANTECH производятся на заводах своего партнера на Тайване. Многолетнее сотрудничество с OEM-производителями подтверждает уровень качества продукции данного завода и дает право нашему заводу-партнеру поставлять оригинальные запчасти ведущим мировым автопроизводителям (Toyota, Honda, Nissan).
        `,
        videos: [],
        content: [
            {
                type: 'video',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '12.5em',
                    'z-index': '1',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 3.65,
                    top: 30,
                    right: -95,
                    scaleX: .8,
                    scaleY: .8 
                },
                videos: [
                    {
                        link: 'ogNBjgUcqO0',
                        title: 'причины скрипа <br>тормозных колодок',
                        description: 'Почему скрипят тормозные колодки? Рассказываем самые частые, но не очевидные причины скрипа.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding-bottom': '12em'
                },
                title: '<b>ТОРМОЗНЫЕ КОЛОДКИ</b>',
                image: 'brake-pads.png',
                description: 'AVANTECH предлагает автомобилистам дисковые и барабанные тормозные колодки, которые гарантируют безопасность движения автомобиля на любой скорости и в любую погоду.'
            },
            {
                type: 'product-type',
                styles: {
                    'margin-bottom': '5em',
                    'padding': '10em 0'
                },
                image: 'brake-shoes.png',
                imageStyles: {
                    'top': '0'
                },
                description: 'Тормозные колодки AVANTECH имеют высокие технические характеристики, обеспечивают плавное эффективное торможение. Отвечают требованиям ECE-R90 - европейского стандарта качества продукции, который выдается после проведения серии испытаний тормозных колодок на работоспособность и соответствие оригинальной детали.'
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        title: 'Стойкость к перегреву',
                        description: 'Ключевым показателем при профессиональном выборе тормозных колодок является стабильность фрикционного материала в широком диапазоне температур, стойкость к перегреву.<br><br>Фрикционный материал тормозных колодок AVANTECH аналогичен оригинальному - тому, который используется при производстве тормозных колодок для новых автомобилей.'
                    },
                    {
                        title: ' ',
                        description: 'Температура нагрева тормозных колодок при соприкосновении с диском в обычном режиме составляет +100 - 200°C, при движении по затяжному спуску температурные нагрузки увеличиваются до +350 - 400°C.'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ ТОРМОЗНЫХ КОЛОДОК:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 5,
                            right: 56,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Стабильные тормозные свойства в различных режимах дорожного движения.',
                                    'Неагрессивное воздействие на тормозной диск - в итоге малый и равномерный износ тормозного диска.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Механическая прочность колодок по стандартам ОЕМ-производителей.',
                                    'Конструкция для эффективного очищения поверхности от фрикционной пыли.',
                                    'Отсутствие в составе колодок асбеста.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Основной ассортимент дисковых тормозных колодок укомплектован противоскрипными пластинами.',
                                    'Температурный диапазон до 400 °C',
                                    'Коэффициент трения 0,35-0,48µ'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Бесплатная противоскрипная смазка для дисковых тормозных колодок в комплекте (страна производства – Южная Корея).',
                                    'Электронные датчики износа для моделей автомобилей с использованием соответствующей системы.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 178.2,
                    top: -65,
                    right: -10,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>Тормозные диски и барабаны</b>',
                image: 'brake-rotor.png',
                description: 'Тормозная система является важнейшим средством обеспечения активной безопасности автомобиля. Несмотря на то, что в последнее время широкое распространение получила тормозная система дискового типа, автопроизводители по-прежнему используют барабанные тормоза на задней оси. В системе барабанного типа торможение осуществляется прижатием тормозных колодок к вращающемуся барабану. Тормозные диски и барабаны AVANTECH имеют высокие технические характеристики и отвечают всем требованиям, предъявляемым OEM-производителям к тормозной системе. Ступица дисков и барабанов окрашена специальной краской, которая выдерживает высокие температуры и гарантирует высокую степень защиты от коррозии.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ ТОРМОЗНЫХ ДИСКОВ:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: -1,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Продукция AVANTECH изготовлена из специального сплава чугуна с повышенным содержанием углерода. Подобные диски успешно используются спортсменами в автомобильных соревнованиях.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Применение технологии обработки поверхности диска STOP and GO уменьшает время притирки дисков с колодками. STOP and GO увеличивает коэффициент сцепления и обеспечивает быструю притирку новых тормозных колодок уже после первого торможения.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Оптимальная теплопроводность обеспечивает повышенное сопротивление деформации и препятствует разрушению диска при его перегреве. Конструкция диска устраняет вибрацию и уменьшает шум при торможении.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Применение в производстве тормозных дисков AVANTECH технологии READY TO GO избавляет автовладельцев от необходимости удаления с поверхности диска защитного слоя масла перед эксплуатацией.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'overflow': 'hidden',
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 30,
                    top: 55,
                    right: -32,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>ПЕРФОРИРОВАННЫЕ ТОРМОЗНЫЕ ДИСКИ</b>',
                image: 'brake-rotor-perf.png',
                imageStyles: {
                    'z-index': '3',
                    'right': '15%'
                },
                description: 'Предназначены для установки на тяжёлые и спортивные автомобили, тормозные системыкоторых испытывают повышенные нагрузки.Специально спроектированные вентиляционные отверстия улучшают отвод газов и тепла из места контакта тормозной колодки и диска, повышая тем самым эффективность торможения.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: -141.3,
                    top: -230,
                    right: 48,
                    scaleX: 1.2,
                    scaleY: 1.2 
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ перфорированных ТОРМОЗНЫХ ДИСКОВ:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 90,
                            top: 16,
                            right: 98,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Продукция AVANTECH изготовлена из специального сплава чугуна с повышенным содержанием углерода. Подобные диски успешно используются спортсменами в автомобильных соревнованиях.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Особая технология обработки уменьшает время притирки дисков с колодками.',
                                    'Конструкция диска устраняет вибрацию и уменьшает шум при торможении.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Оптимальная теплопроводность обеспечивает повышенное сопротивление деформации и препятствует разрушению диска при его перегреве.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Применение в производстве тормозных дисков AVANTECH технологии READY TO GO избавляет автовладельцев от необходимости удаления с поверхности диска защитного слоя масла перед эксплуатацией.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};