export const en = {
    anthers: {
        description: `
            AVANTECH dust boots are manufactured in spare parts manufacturing plants producing both original and analog parts in Taiwan and Malaysia. Production complies with international quality standards ISO/TS 16949.
        `,
        videos: [],
        styles: {
            'margin-top': '5em',
        },
        content: [
            {
                type: 'product',
                styles: {
                    'padding-bottom': '14em'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 88.2,
                    top: -58,
                    right: -23,
                    scaleX: 1,
                    scaleY: 1
                },
                image: 'anthers-boot.png',
                imageStyles: {
                    'top': '60%'
                },
                description: 'The dust boot is a rubber boot necessary to protect various parts of the car from dust, dirt, moisture, sand and other foreign materials. They also protect the internal lubrication from contamination and increase the life of the suspension.<br><br>AVANTECH\'s range of dust boots includes more than 170 items and is designed for Japanese cars.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding-bottom': '14em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -86.4,
                    top: -660,
                    right: -61,
                    scaleX: .9,
                    scaleY: .9
                },
                image: 'anthers-boot-rack.png',
                imageStyles: {
                    'top': '20%'
                },
                description: 'In the suspension of a car, anthers are necessary to protect the CV joints (constant velocity joints), steering rods, shock absorbers, steering rack and much more. In total, there can be about 40 to 60 of them in a car, but the most important are 10 or 20. Improper operation of these parts can lead to breakdown and costly car repairs.'
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'padding-bottom': '7.5em'
                },
                templates: [
                    {
                        title: 'High strength and elasticity',
                        description: 'AVANTECH anthers are made taking into account the aggressive impact of reagents - sand-salt mixture and oil products, which increases their service life and resistance to mechanical damage.'
                    },
                    {
                        title: ' ',
                        description: 'In the production of AVANTECH anthers, high-density neoprene is used, which allows them to maintain their properties at temperatures from -50°C to +90°C without loss of strength and elasticity, which allows them to be used even in the northern regions.'
                    }
                ]
            },
        ]
    }
};