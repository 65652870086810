export const ru = {
    anthers: {
        description: `
            Пыльники AVANTECH изготавливаются на заводах-производителях запасных частей, производящих как оригинальные, так и аналоговые детали на Тайване и в Малайзии. Производство соответствует международным системам стандартов качества ISO/TS 16949.
        `,
        videos: [],
        styles: {
            'margin-top': '5em',
        },
        content: [
            {
                type: 'product',
                styles: {
                    'padding-bottom': '14em'
                },
                pseudo: {
                    type: 'marks',
                    index: 0,
                    rotate: 88.2,
                    top: -58,
                    right: -23,
                    scaleX: 1,
                    scaleY: 1
                },
                image: 'anthers-boot.png',
                imageStyles: {
                    'top': '60%'
                },
                description: 'Пыльник – это резиновый чехол, необходимый для защиты различных узлов автомобиля от пыли, грязи, влаги, песка и других посторонних материалов. Они также защищают внутреннюю смазку от загрязнения и увеличивают срок эксплуатации подвески.<br><br>Ассортимент пыльников AVANTECH насчитывает более 170 наименований и предназначены для японских автомобилей.'
            },
            {
                type: 'product-type',
                styles: {
                    'padding-bottom': '14em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -86.4,
                    top: -580,
                    right: -61,
                    scaleX: .9,
                    scaleY: .9
                },
                image: 'anthers-boot-rack.png',
                imageStyles: {
                    'top': '20%'
                },
                description: 'В подвеске автомобиля пыльники необходимы для защиты ШРУС (шарниров равных угловых скоростей), рулевых тяг, амортизаторов, рулевой рейки и многого другого. Всего в машине их может быть около 40 – 60 штук, но наиболее важными являются 10 или 20. Неправильная работа этих деталей может привести к поломке и дорогостоящему ремонту автомобиля.'
            },
            {
                type: 'description-box',
                split: false,
                styles: {
                    'padding-bottom': '7.5em'
                },
                templates: [
                    {
                        title: 'Высокая прочность и эластичность',
                        description: 'Пыльники AVANTECH изготовлены с учётом агрессивного воздействия реагентов - песко-солевой смеси и нефтепродуктов, что увеличивает сроки их эксплуатации и сопротивляемость механическим повреждениям.'
                    },
                    {
                        title: ' ',
                        description: 'В производстве пыльников AVANTECH применяется неопрен высокой плотности, что позволяет им сохранять свои свойства при температурах от –50°C до +90°C без потери прочности и эластичности, что позволяет их использовать даже в северных регионах.'
                    }
                ]
            },
        ]
    }
};