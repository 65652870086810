export const ru = {
    rubber: {
        description: `Наиболее нагруженными узлами автомобиля являются детали ходовой части, в первую очередь - резинотехнические изделия (РТИ), которые принимают на себя наибольшую часть ударных нагрузок от движения по неровностям дорожного покрытия. К данной категории относятся сайлентблоки рычагов, втулки стабилизаторов поперечной устойчивости, опоры амортизаторов, а также другие РТИ, отвечающие за корректную работу таких узлов, как двигатель и трансмиссия автомобиля. Сюда можно отнести опоры (подушки) двигателя и трансмиссии автомобиля.`,
        videos: [],
        content: [
            {
                type: 'description-box',
                split: true,
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '3',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: 100,
                    right: 95,
                    scaleX: 1,
                    scaleY: 1 
                },
                templates: [
                    {
                        title: 'Соответствуют стандартам качества',
                        description: 'Сайлентблоки и втулки AVANTECH хорошо зарекомендовали себя при эксплуатации в суровом российском климате с пониженными температурами. В российских лабораториях продукция AVANTECH успешно прошла все тесты на разрыв, кручение, смещение, что позволяет с гарантировать соответствие международным стандартам качества.'
                    },
                    {
                        title: 'Производство',
                        description: 'Avantech Parts Group изготавливает резинотехнические изделия детали под брендом AVANTECH подвески и рулевого управления на заводах-производителях запасных частей, расположенных в Таиланде и Малайзии. На конвейерах этих заводов производятся как оригинальные, так и аналоговые детали мировых брендов.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 84.1,
                    top: 35,
                    right: -180,
                    scaleX: .9,
                    scaleY: .9 
                },
                title: '<b>САЙЛЕНТБЛОКИ</b>',
                image: 'rubber-suspension.png',
                imageStyles: {
                    'right': '5%',
                },
                description: 'Необходимы для принятия ударной нагрузки на подвеску. Сайлентблок представляет из себя жесткое шарнирное соединение, в котором между двумя металлическими втулками находится резиновая вставка. Именно от состояния сайлентблоков зависит скорость износа автомобильных покрышек, качество торможения автомобиля, а также связь с рулевым управлением. Неисправный сайлентблок ведет к ускоренному износу всех деталей подвески, а значит и к дорогостоящему ремонту автомобиля в последующем.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'ПРЕИМУЩЕСТВА САЙЛЕНТБЛОКОВ:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 90,
                            top: 22,
                            right: 98,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 2,
                                content: [
                                    'Сайлентблоки AVANTECH разработаны из высококачественной резиновой смеси повышенной износостойкости, что обеспечивает долговременный превосходный контроль над управляемостью автомобиля в самых экстремальных условиях вождения.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Резинотехническая смесь, применяемая в сайлентблоках AVANTECH, гарантированно сохраняет эластичность, упругость и прочность при температуре до -40⁰С.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Соответствуют оригинальным стандартам качества производителей автомобилей.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'padding': '11em 0 9em'
                },
                title: '<b>ВТУЛКИ СТАБИЛИЗАТОРА ПОПЕРЕЧНОЙ УСТОЙЧИВОСТИ</b>',
                image: 'rubber-hub.png',
                imageStyles: {
                    'z-index': '4'
                },
                description: 'Стабилизатор поперечной устойчивости служит для уменьшения боковых кренов автомобиля в поворотах. Его работа основана на перераспределении нагрузки. Он необходим и даже незаменим при езде на высоких скоростях. Стабилизатор помогает автомобилю максимально быстро, не теряя скорости, пройти поворот, предотвращая его опрокидывание.<br><br>Эффективную работу стабилизатора поперечной устойчивости обеспечивают упругие элементы, одни из которых - это втулки стабилизатора поперечной устойчивости. Благодаря совокупной работе деталей стабилизатора мы чувствуем комфорт и безопасность при прохождении крутых поворотов.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding': '5em 0 7.5em',
                    'background': '#FFF',
                    'z-index': '3',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'ОСОБЕННОСТИ ВТУЛОК СТАБИЛИЗАТОРА:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 6,
                            right: 83,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Втулки стабилизатора AVANTECH изготавливаются из высококачественной резиновой смеси повышенной износостойкости.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Они рассчитаны на эксплуатацию в жёстких российских условиях.',
                                    'Снижают уровень вибрации.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Имеют высокую прочность и устойчивость к механическим воздействиям.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Соответствуют оригинальным стандартам качества производителей автомобилей.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};