export const en = {
    lamps: {
        description: `
            One of the best offers in the middle price range in terms of price, quality and range. Cover the needs of a modern fleet of European, Japanese, Korean and American production.
            <br><br>
            Produced exclusively in South Korea in a modern robotic production. Every AVANTECH lamp passes a filament geometry test, a gas leak test and a vibration test.
        `,
        videos: [],
        content: [
            {
                type: 'product',
                styles: {
                    'margin': '5em 0',
                    'overflow': 'hidden'
                },
                pseudo: {
                    type: 'lines-cut',
                    index: 0,
                    rotate: 0,
                    top: -25,
                    right: -65,
                    scaleX: 1,
                    scaleY: 1
                },
                title: 'MAXIMUM WHITE<br> STYLISH LIGHT<br> <b>NIGHT FIGHTER</b>',
                image: 'lamps-night.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'AVANTECH NIGHT FIGHTER series - lamps with a blue bulb coating. The increased brightness and color temperature of 5000K provide such lamps with continued popularity among modern car owners. AVANTECH NIGHT FIGHTER lamps give the headlights an intense white tint with the effect of a xenon light source. Guarantee maximum contrast and clear detail of the road situation. <br><br>Full compliance with all the requirements of the technical regulations of the Russian Federation makes it possible to install lamps as standard light on both private and commercial vehicles.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'NIGHT FIGHTER SERIES FEATURES',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 120,
                            right: 76,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Color temperature 5000K - cool white light, comfortable for the driver\'s eyes.',
                                    '+110% light from headlights on the road at night.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '+50% whiter light (compared to a standard lamp).',
                                    'The increased light beam gives more reaction time.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Modern, stylish look of car headlights.',
                                    'EAC and ECE certificates.',
                                    'Set 2 pcs. in a blister pack.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Quartz glass for extended life.',
                                    'Complete range for most modern vehicles with lamp sockets 9005 (HB3) 9006 (HB4) H1 H11 H16 H27/1 H3 H4 H7 H8 H9.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                title: 'ROAD CONTROL IN<br> RAIN, FOG AND SNOW<br> <b>FOG FIGHTER</b>',
                image: 'lamps-fog.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'AVANTECH FOG FIGHTER series - lamps with anti-fog effect. They give a warm yellow light with a temperature of 3000 K. They shine brighter by 50% compared to standard lamps in difficult climatic conditions (rain, fog, snow). Can be installed as a primary or secondary headlight.<br><br>The yellow sunny tint to the light of AVANTECH FOG FIGHTER lamps is provided by a special interference coating applied to the flasks. The 3000K color temperature improves the contrast on the road while driving in adverse weather conditions. The fact is that the pure white headlights during precipitation are intensely reflected from small drops of moisture or snowflakes and blind the driver. Yellow light in these conditions is more preferable, as it does not overload the driver\'s eyes and allows you to see further. In addition, in bad weather conditions, a car with AVANTECH FOG FIGHTER lamps is much more visible on the road, which significantly reduces the risk of an accident.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'FOG FIGHTER SERIES FEATURES:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Color temperature 3000K - lamps with yellow light for difficult weather conditions.',
                                    '+50% light in fog, rain, snow.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Soft, comfortable yellow light.',
                                    'Quartz glass for extended life.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'EAC and ECE certificates',
                                    'Set 2 pcs. in a blister pack.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Complete range for most modern vehicles with lamp sockets 9005 (HB3) 9006 (HB4) H1 H11 H16 H27/1 H3 H4 H7 H8 H9.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'z-index': '2',
                    'position': 'relative'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: -30,
                    top: -115,
                    right: -25,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>HALOGEN LAMPS</b>',
                image: 'lamps-halogen.png',
                description: 'AVANTECH halogen lamps are a classic solution proven over the years of production and operation. They optimally combine workmanship, guaranteed service life and a favorable price. Typically, lamps of this type are installed as standard in many cars and, according to European standards, are used as a mandatory spare set.'
            },
            {
                type: 'grid-table',
                styles: {
                    'padding-top': '5em',
                    'padding-bottom': '12.5em',
                    'background': '#FFF',
                    'z-index': '2',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'FEATURES OF HALOGEN LAMPS:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: 10,
                            right: 92,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Quartz glass for extended life.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Conform to OEM standards.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'EAC and ECE certificates'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Complete range for most modern vehicles with 9005 (HB3) lamp bases.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding' : '14em 0'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 96.8,
                    top: -300,
                    right: -55,
                    scaleX: 0.7,
                    scaleY: -0.7
                },
                title: '<b>xenon lamps</b>',
                image: 'lamps-hid.png',
                description: 'Xenon lamps AVANTECH fully comply with the factory parameters of the original lamps and are designed to replace standard xenon.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em',
                    'z-index': '1',
                    'position': 'relative'
                },
                tables: [
                    {
                        title: 'FEATURES OF XENON LAMPS:',
                        pseudo: {
                            type: 'marks',
                            index: 1,
                            rotate: 178.2,
                            top: 142,
                            right: -12,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'The color temperature of the lamps is 4300 K.',
                                    'The luminous flux of a xenon lamp is 80% greater than that of a standard halogen lamp.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Another key advantage of xenon lamps is their lifetime: they last up to 5 times longer than halogen lamps.'
                                ]
                            },
                            {
                                collapse: 2,
                                content: [
                                    'In the production of lamps, only modern materials are used - quartz glass, molybdenum filament from leading European manufacturers and ceramic tubes from Japan.'
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -55,
                    right: 110,
                    scaleX: 1,
                    scaleY: 1
                },
                title: '<b>LED bulbs</b>',
                image: 'lamps-led.png',
                description: 'AVANTECH LED lamps are a new modern light source, the emergence of which has become possible thanks to the development of high technologies.<br><br>LED light sources have become the next stage in the development of the automotive lamp industry. Compared with the already familiar halogen and xenon lamps, they have a number of undeniable advantages.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'FEATURES OF LED LAMPS:',
                        pseudo: {
                            type: 'mountains',
                            index: 0,
                            rotate: 0,
                            top: 13,
                            right: 91,
                            scaleX: 1,
                            scaleY: 1
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Durability - up to 20,000 hours.',
                                    'Low heat generation - safe for plastic headlights.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Style - bright white light up to 6500K.',
                                    'Low power consumption - up to 2.5 times lower than a halogen lamp.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Efficiency - up to 110 lumens from 1 watt.',
                                    'Safety - does not contain mercury, does not emit in the UV spectrum.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Production 100% Korea. LEDs manufactured by LUMIMICRO and LG INNOTEK LED.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    }
};