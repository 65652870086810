export const kr = {
    breadcrumbs: {
        home: { name: '본토', to: '/' },
        whereToBuy: { name: 'Where to buy', to: '/store' },
        catalog: { name: '목록', to: '/catalog' },
        about: { name: '회사 소개', to: '/about'},
        assurance: { name: '보증', to: '/assurance' },
        inform: { name: '정보', to: '/inform'},
        partners: { name: '파트너', to: '/partners'},
        anthers: { name: '가루잠금장치', to: '/catalog/anthers'},
        belts: { name: '의 구동벨트', to: '/catalog/belts'},
        discs: { name: '디스크 브레이크와 드럼 브레이크', to: '/catalog/discs'},
        filters: { name: '필터', to: '/catalog/filters'},
        lamps: { name: '램프는', to: '/catalog/lamps'},
        brakes: { name: '브레이크 시스템', to: '/catalog/brakes'},
        pumps: { name: '연료 펌프', to: '/catalog/pumps'},
        rubber: { name: '고무제 파트', to: '/catalog/rubber'},
        springs: { name: '서스펜션 스프링', to: '/catalog/springs'},
        steering: { name: '서스펜션 및 조향장치 파트', to: '/catalog/steering'},
        wipers: { name: '와이퍼 블레이드', to: '/catalog/wipers'},
    },
};
